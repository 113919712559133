/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from 'react';
import { useLocation, Route, Routes, Navigate, Link } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";


// core components
import AuthNavbar from "../../panel/admin/inc/Navbar/AuthNavbar";
import AuthFooter from "../../panel/admin/inc/Footer/AuthFooter";

// import routes from "./routes.js";
import { routesForLogin } from "./routes.js";
import { Loader } from '../../components/Loader.jsx';
const Auth = () => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const currentPath = location.pathname;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [currentPath]);
  React.useEffect(() => {
    // document.body.classList.add("bg-default");
    // return () => {
    //   document.body.classList.remove("bg-default");
    // };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    //mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routesForLogin) => {
    return routesForLogin.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  return (
   
    <>
      {
        loading ? <>
          <Loader />
        </>
          :
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        <div className="header global_gradiant py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mt-5">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                
                  <h1 className="text-white mb-0">Welcome!</h1>
                  <p className="text-lead text-light mb-1">
                  Log in to your account
                  </p>
               
                </Col>
              </Row>
            </div>
          </Container>
          {/* <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div> */}
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
    
          <Row className="justify-content-center">
            <Routes>
              {getRoutes(routesForLogin)}
              <Route path="*" element={<Navigate to="/auth/login" replace />} />
            </Routes>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
    }
    </>
  );
};

export default Auth;
