
import React, { useState, useLayoutEffect } from "react";
import { FaEdit, FaTrash } from 'react-icons/fa';
import config from "../../../config.jsx";
import { apiBaseURL, getAllLocatData, getCurrentDate, toastifyError, toastifySuccess } from '../../../components/Utility';
import { axiosInstance } from '../../../api/interceptor'; import Table from 'react-bootstrap/Table';
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Container,
  Row,
  Input,
  Col,
} from "reactstrap";
// core components
import Header from "../../../panel/admin/inc/Header/Header.js";
import EditForm from "./updateUser.js";
import { Loader } from "../../../components/Loader.jsx";

const UserList = () => {
  const [userData, setUserData] = useState([])

  const [editUserData, setEditUserData] = useState({});
  const [page, setPage] = useState(false)
  const [updatedUserData, setUpdatedUserData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage,setPerPAge] = useState(10);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filteredUserData.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  useLayoutEffect(() => {
    getUserDetails();
  }, [currentPage]);

  const getUserDetails = async () => {
    setLoading(true);
    const skip = (currentPage - 1) * postsPerPage;
    const limit = postsPerPage;
    try {
      const response = await axios.post(
        `${config.url}/user/all_users`,
        { skip, limit },
        {headers: {'Content-Type': 'application/json'} }
      );

      if (response.data.status === 200) {
        setUserData(response.data);
        setFilteredUserData(response.data.user);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };


  const handleEdit = (userData) => {
    setEditUserData(userData)
    setPage(true)
  };

  const handleDelete = async (userData) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete ${userData.first_name} ${userData.last_name}?`);
    if (!isConfirmed) return;

    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const raw = JSON.stringify({
        "userId": userData?._id
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      const response = await fetch(`${apiBaseURL()}/user/deleteUser`, requestOptions);
      const result = await response.json();
      if (result?.status === 200) {
        getUserDetails();
        toastifySuccess(result.message || "User deleted successfully");
      } else {
        toastifyError(result.message || "Something went wrong while deleting user");
      }
    } catch (error) {
      console.error(error);
      toastifyError(error?.res?.data?.message || "Something went wrong while deleting user");
    }

  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filteredData = userData?.user?.filter(user => {
        return (
            user?.first_name.toLowerCase().includes(query) ||
            user?.prefix.toLowerCase().includes(query) ||
            user?.last_name.toLowerCase().includes(query) ||
            user?.email.toLowerCase().includes(query) ||
            user?.phone.toLowerCase().includes(query)
        );
    });
    setFilteredUserData(filteredData);
  };

  const handleFilterChange = (e) =>{
    const name = e.target.name
    const value = e.target.value

    if(name == 'sort'){
      if(value == 10){
        setPerPAge(value)
        setFilteredUserData(userData?.user)
      }else{
        setPerPAge(value)
        const datacount =  userData?.user?.slice(0, parseInt(value));
        setFilteredUserData(datacount)
      }
    }
  }


  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              {!page ? (
                <>
                  <CardHeader className="border-0">
                    <Row className="align-items-center justify-content-between">

                      <Col lg={7} md={6}>
                        <h3 className="mb-0">User List :{userData?.user?.length} Users</h3>
                      </Col>
                      <Col lg={2}>
                        <div className="sort_btn">
                          <select className="w-100" name="sort" onChange={handleFilterChange}>
                            <option value={10}>Sort By</option>
                            <option value={20}>20</option>
                            <option value={30}>30</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </select>
                        </div>
                      </Col>
                      <Col lg={3} md={6}>
                        <div className="global_input">
                          <Input
                            type="text"
                            placeholder="Search by Name, Email, or Phone"
                            value={searchQuery}
                            onChange={handleSearch}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  {filteredUserData ? (
                    <CardFooter className="py-4">
                      <nav className="cs_pagination" aria-label="...">
                        <Pagination
                          className="pagination justify-content-end mb-0 align-items-center p-2"
                          listClassName="justify-content-end mb-0"
                        >
                          <ReactPaginate
                            onPageChange={paginate}
                            pageCount={Math.ceil(filteredUserData.length / postsPerPage)}
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            containerClassName={'pagination'}
                            pageLinkClassName={'page-number'}
                            previousLinkClassName={'page-number'}
                            nextLinkClassName={'page-number'}
                            activeLinkClassName={'active'}
                          />
                        </Pagination>
                      </nav>
                    </CardFooter>
                  ) : (
                    <div className="loading">Loading...</div>
                  )}
                  <Table className="align-items-center " responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Full Name</th>
                        <th scope="col">DOB</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone</th>
                        <th scope="col">Country</th>
                        <th scope="col">Language</th>
                        <th scope="col">Language Level</th>
                        <th scope="col">Status</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>


                    <tbody>

                      {currentPosts.map((userData, i) => (
                        <>
                          <tr>
                            <td >{`${userData.prefix} ${userData.first_name} ${userData.last_name}`}</td>
                            <td>{userData.dob}</td>
                            <td>{userData.email}</td>
                            <td>{userData.phone}</td>
                            <td>{userData.country}</td>
                            <td>{userData.language}</td>
                            <td>{userData.language_level}</td>
                            <td>{userData.status}</td>
                            <td className="text-right">
                              <button className="edit_btn" onClick={() => handleEdit(userData)}>  <FaEdit /></button>
                              <button className="dlt_btn" onClick={() => handleDelete(userData)}><FaTrash /></button>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </Table>
                  {filteredUserData ? (
                    <CardFooter className="py-4">
                      <nav className="cs_pagination" aria-label="...">
                        <Pagination
                          className="pagination justify-content-end mb-0 align-items-center p-2"
                          listClassName="justify-content-end mb-0"
                        >
                          <ReactPaginate
                            onPageChange={paginate}
                            pageCount={Math.ceil(filteredUserData.length / postsPerPage)}
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            containerClassName={'pagination'}
                            pageLinkClassName={'page-number'}
                            previousLinkClassName={'page-number'}
                            nextLinkClassName={'page-number'}
                            activeLinkClassName={'active'}
                          />
                        </Pagination>
                      </nav>
                    </CardFooter>
                  ) : (
                    <div className="loading">Loading...</div>
                  )}
                </>
              ) : (

                <CardFooter className="py-4">
                  <EditForm
                    userData={editUserData}
                    setPage={setPage}
                    userListFun={getUserDetails}
                  />
                  <nav className="cs_pagination" aria-label="...">
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>

      </Container>
    </>
  );
};

export default UserList;
