
import { useNavigate } from "react-router-dom";

const BackBTN = () => {
  const navigate = useNavigate();
  const handleback = () => {
    navigate(-1);
  };
  return (
    <>
      <button className="back_btn" onClick={handleback}>
        <i className="fa-solid fa-arrow-left"></i>
      </button>
    </>
  );
};

export default BackBTN;
