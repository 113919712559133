import React, { useState } from "react";
import "./css/style.css";
import "./css/responsive.css";
import { Col, Container, Row } from "react-bootstrap";
// import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from "react-bootstrap/DropdownButton";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import placeimg from "../../../assets/images/destination.png";
import iconimg from "../../../assets/images/chat.png";
import mall from "../../../assets/images/mall.png";
import attractive from "../../../assets/images/attractive.png";
import notdoneimg from "../../../assets/images/notdone.png";
import faqimg from "../../../assets/images/faqbanner.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import placesec from "../../../assets/images/Brisbane.jpg";
import placethird from "../../../assets/images/Melbourne.webp";
import placefour from "../../../assets/images/Cairns.jpg";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import type { DatePickerProps } from "antd";

import { DatePicker, Space } from "antd";
const Home = () => {
  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const popular = {
    loop: true,
    autoplay: true,
    autoplaySpeed: 100,
    margin: 10,
    dots: false,
    nav: false,
    responsiveClass: true,
    infinite: true,
    speed: 100,

    responsive: {
      0: {
        items: 1.1,
      },
      600: {
        items: 2.2,
      },
      1000: {
        items: 4,

        loop: true,
      },
    },
  };

  const facilities = {
    loop: true,
    autoplay: true,
    autoplaySpeed: 100,
    margin: 10,
    dots: false,
    nav: false,
    responsiveClass: true,
    infinite: true,
    speed: 100,

    responsive: {
      0: {
        items: 1.1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,

        loop: true,
      },
    },
  };

  const [quantity, setQuantity] = useState(1);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    // You can add any logic for maximum quantity if needed
    setQuantity(quantity + 1);
  };

  return (
    <div>
      <section className="slider_hero">
        <Container>
          <Col lg={6}>
            <div className="slider_cnt">
              <h1
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                Embark on virtual adventures! With <span>virtual flights</span>{" "}
                from home
              </h1>
              <Link className="button1" data-aos="zoom-in">
                Book Now
              </Link>
            </div>
          </Col>
        </Container>
      </section>

      <section className="ticketbook">
        <Container>
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="border-0 mb-3"
          >
            <Tab eventKey="home" title="One Way">
              <Row>
                <Col md={6} lg={4} className="mb-3">
                  <div class="form_search_date">
                    <div class="flight_Search_boxed date_flex_area">
                      <div class="Journey_date">
                        <p>Journey date</p>
                        <Space direction="vertical">
                          <DatePicker onChange={onChange} />
                        </Space>
                        <span>LCY, London city airport</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  md={6}
                  lg={3}
                  className="mb-3"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div class="flight_Search_boxed">
                    <p>From</p>
                    <input type="text" placeholder="Where You Want To Go" />
                    <span>JFK - John F. Kennedy International...</span>
                    <div class="plan_icon_posation">
                      <i class="fas fa-plane-departure"></i>
                    </div>
                  </div>
                </Col>
                <Col
                  md={6}
                  lg={3}
                  className="mb-3"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div class="flight_Search_boxed">
                    <p>To</p>
                    <input type="text" placeholder="Where You Want To Go" />
                    <span>DXB-Dubai</span>
                    <div class="plan_icon_posation">
                      <i class="fa-solid fa-plane-arrival"></i>
                    </div>
                    <div class="range_plan">
                      <i class="fas fa-exchange-alt"></i>
                    </div>
                  </div>
                </Col>

                <Col
                  md={6}
                  lg={2}
                  className="mb-3 outer_for_overlay"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div class="flight_Search_boxed dropdown_passenger_area">
                    <p>Passenger, Class </p>
                    <div class="dropdown">
                      <DropdownButton
                        id="dropdown-basic-button"
                        title="0 Passenger"
                      >
                        <div class="traveller-calulate-persons">
                          <div class="passengers">
                            <h6>Passengers</h6>
                            <div class="passengers-types">
                              <div class="passengers-type">
                                <div class="text">
                                  <span class="count pcount">{quantity}</span>
                                  <div class="type-label">
                                    <p>Adult</p>
                                    <span>12+ yrs</span>
                                  </div>
                                </div>
                                <div className="quantity_select">
                                  <button onClick={decreaseQuantity}>-</button>

                                  <button onClick={increaseQuantity}>+</button>
                                </div>
                              </div>
                              <div class="passengers-type">
                                <div class="text">
                                  <span class="count ccount">{quantity}</span>
                                  <div class="type-label">
                                    <p class="fz14 mb-xs-0">Children</p>
                                    <span>2 - Less than 12 yrs</span>
                                  </div>
                                </div>
                                <div className="quantity_select">
                                  <button onClick={decreaseQuantity}>-</button>

                                  <button onClick={increaseQuantity}>+</button>
                                </div>
                              </div>
                              <div class="passengers-type">
                                <div class="text">
                                  <span class="count incount">{quantity}</span>
                                  <div class="type-label">
                                    <p class="fz14 mb-xs-0">Infant</p>
                                    <span>Less than 2 yrs</span>
                                  </div>
                                </div>
                                <div className="quantity_select">
                                  <button onClick={decreaseQuantity}>-</button>

                                  <button onClick={increaseQuantity}>+</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="cabin-selection">
                            <h6>Cabin Class</h6>
                            <div class="cabin-list">
                              <button type="button" class="label-select-btn">
                                <span class="muiButton-label">Economy</span>
                              </button>
                              <button
                                type="button"
                                class="label-select-btn active"
                              >
                                <span class="muiButton-label">Business</span>
                              </button>
                              <button type="button" class="label-select-btn">
                                <span class="MuiButton-label">
                                  First Class{" "}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </DropdownButton>
                      <div
                        class="dropdown-menu dropdown_passenger_info"
                        aria-labelledby="dropdownMenuButton1"
                      ></div>
                    </div>
                    <span>Business</span>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="search_btn">
                    <Link className="button1" to="/flightlist">
                      Search
                    </Link>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="profile" title="Return">
              <Row>
                <Col md={6} lg={4} className="mb-3">
                  <div class="form_search_date">
                    <div class="flight_Search_boxed date_flex_area">
                      <div class="Journey_date">
                        <p>Journey date</p>
                        <Space direction="vertical">
                          <DatePicker onChange={onChange} />
                        </Space>
                        <span>LCY, London city airport</span>
                      </div>

                      <div class="retundate">
                        <p>Return date</p>
                        <Space direction="vertical">
                          <DatePicker onChange={onChange} />
                        </Space>
                        <span>LCY, London city airport</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  md={6}
                  lg={3}
                  className="mb-3"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div class="flight_Search_boxed">
                    <p>From</p>
                    <input type="text" placeholder="Where You Want To Go" />
                    <span>JFK - John F. Kennedy International...</span>
                    <div class="plan_icon_posation">
                      <i class="fas fa-plane-departure"></i>
                    </div>
                  </div>
                </Col>
                <Col
                  md={6}
                  lg={3}
                  className="mb-3"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div class="flight_Search_boxed">
                    <p>To</p>
                    <input type="text" placeholder="Where You Want To Go" />
                    <span>DXB-Dubai</span>
                    <div class="plan_icon_posation">
                      <i class="fa-solid fa-plane-arrival"></i>
                    </div>
                    <div class="range_plan">
                      <i class="fas fa-exchange-alt"></i>
                    </div>
                  </div>
                </Col>

                <Col
                  md={6}
                  lg={2}
                  className="mb-3 outer_for_overlay"
                  data-aos="fade-up"
                  data-aos-duration="1500"
                >
                  <div class="flight_Search_boxed dropdown_passenger_area">
                    <p>Passenger, Class </p>
                    <div class="dropdown">
                      <DropdownButton
                        id="dropdown-basic-button"
                        title="0 Passenger"
                      >
                        <div class="traveller-calulate-persons">
                          <div class="passengers">
                            <h6>Passengers</h6>
                            <div class="passengers-types">
                              <div class="passengers-type">
                                <div class="text">
                                  <span class="count pcount">{quantity}</span>
                                  <div class="type-label">
                                    <p>Adult</p>
                                    <span>12+ yrs</span>
                                  </div>
                                </div>
                                <div className="quantity_select">
                                  <button onClick={decreaseQuantity}>-</button>

                                  <button onClick={increaseQuantity}>+</button>
                                </div>
                              </div>
                              <div class="passengers-type">
                                <div class="text">
                                  <span class="count ccount">{quantity}</span>
                                  <div class="type-label">
                                    <p class="fz14 mb-xs-0">Children</p>
                                    <span>2 - Less than 12 yrs</span>
                                  </div>
                                </div>
                                <div className="quantity_select">
                                  <button onClick={decreaseQuantity}>-</button>

                                  <button onClick={increaseQuantity}>+</button>
                                </div>
                              </div>
                              <div class="passengers-type">
                                <div class="text">
                                  <span class="count incount">{quantity}</span>
                                  <div class="type-label">
                                    <p class="fz14 mb-xs-0">Infant</p>
                                    <span>Less than 2 yrs</span>
                                  </div>
                                </div>
                                <div className="quantity_select">
                                  <button onClick={decreaseQuantity}>-</button>

                                  <button onClick={increaseQuantity}>+</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="cabin-selection">
                            <h6>Cabin Class</h6>
                            <div class="cabin-list">
                              <button type="button" class="label-select-btn">
                                <span class="muiButton-label">Economy</span>
                              </button>
                              <button
                                type="button"
                                class="label-select-btn active"
                              >
                                <span class="muiButton-label">Business</span>
                              </button>
                              <button type="button" class="label-select-btn">
                                <span class="MuiButton-label">
                                  First Class{" "}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </DropdownButton>
                      <div
                        class="dropdown-menu dropdown_passenger_info"
                        aria-labelledby="dropdownMenuButton1"
                      ></div>
                    </div>
                    <span>Business</span>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="search_btn">
                    <Link className="button1" to="/flightlist">
                      Search
                    </Link>
                  </div>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Container>
      </section>

      <section className="popular_section">
        <Container>
          <Row className="justify-content-between">
            <Col md={6}>
              <div className="global_geading">
                <p>Destination</p>
                <h2>Popular Destination</h2>
              </div>
            </Col>

            <Col md={3}>
              <div className="global_select">
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Departure From
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      label="Age"
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>Perth Airport</MenuItem>
                      <MenuItem value={20}>Adelaide Airport</MenuItem>
                      <MenuItem value={30}>Cairns Airport</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
            </Col>
          </Row>

          <div
            className="main_place_outer"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            <Row>
              <OwlCarousel className="owl-theme" {...popular}>
                <div class="item">
                  <div className="flight_box">
                    <div className="img_flight_destination">
                      <img src={placeimg} alt="flight place image" />
                    </div>
                    <div className="over_lay">
                      <a href="#" className="button1">
                        Book Now
                      </a>
                      <div className="line"></div>
                    </div>
                    <div className="destination_name">
                      <h2>Sydney</h2>
                      <p>$200</p>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div className="flight_box">
                    <div className="img_flight_destination">
                      <img src={placesec} alt="flight place image" />
                    </div>
                    <div className="over_lay">
                      <a href="#" className="button1">
                        Book Now
                      </a>
                      <div className="line"></div>
                    </div>
                    <div className="destination_name">
                      <h2>Brisbane</h2>
                      <p>$200</p>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div className="flight_box">
                    <div className="img_flight_destination">
                      <img src={placethird} alt="flight place image" />
                    </div>
                    <div className="over_lay">
                      <a href="#" className="button1">
                        Book Now
                      </a>
                      <div className="line"></div>
                    </div>
                    <div className="destination_name">
                      <h2>Melbourne </h2>
                      <p>$200</p>
                    </div>
                  </div>
                </div>

                <div class="item">
                  <div className="flight_box">
                    <div className="img_flight_destination">
                      <img src={placefour} alt="flight place image" />
                    </div>
                    <div className="over_lay">
                      <a href="#" className="button1">
                        Book Now
                      </a>
                      <div className="line"></div>
                    </div>
                    <div className="destination_name">
                      <h2>Cairns </h2>
                      <p>$200</p>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </Row>
          </div>
        </Container>
      </section>

      <section className="Facilities_we_Offer">
        <Container>
          <div className="global_geading text-center">
            <p>FACILITIES</p>
            <h2>Facilities We Offer</h2>
          </div>
          <div className="Facilities_we_Offer_outer">
            <Row>
              <Col md={4} data-aos="zoom-in" data-aos-duration="1000">
                <div className="facilities_box text-center">
                  <div className="faciliteis_icon">
                    <img src={iconimg} alt="icon img" />
                  </div>
                  <div className="name">Chats</div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </Col>
              <Col
                md={4}
                data-aos="zoom-in"
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                <div className="facilities_box text-center">
                  <div className="faciliteis_icon">
                    <img src={attractive} alt="icon img" />
                  </div>
                  <div className="name">Chats</div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </Col>
              <Col
                md={4}
                data-aos="zoom-in"
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                <div className="facilities_box text-center">
                  <div className="faciliteis_icon ">
                    <img src={mall} alt="icon img" />
                  </div>
                  <div className="name">Chats</div>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="have_not_done">
        <Container>
          <div className="have_not_done_inner">
            <Row className="align-items-center">
              <Col lg={7} className="order-lg-2 order-1">
                <div
                  className="left_cnt"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h2>Have’t done any Virtual flight yet</h2>
                  <p>
                    It’s free and fast to join GretenAir Join now, earn Points,
                    Redeem tomorrow*
                  </p>
                  <a href="#" className="button1">
                    Book Flight
                  </a>
                </div>
              </Col>
              <Col lg={5} className="order-lg-2 order-1" data-aos="zoom-in">
                <img className="w-100" src={notdoneimg} alt="img" />
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      <section className="faq">
        <Container>
          <div className="global_geading text-center mb-5">
            <p>FACILITIES</p>
            <h2>Facilities We Offer</h2>
          </div>
          <div className="faq_inner">
            <Row className="align-items-center">
              <Col md={6}>
                <div className="faq_img">
                  <img src={faqimg} alt="faq img" />
                </div>
              </Col>
              <Col md={6}>
                <div className="quoations">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        What is virtual flight?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        How can i purchase a ticket?
                      </Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Home;
