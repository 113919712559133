
import { Container } from 'react-bootstrap'
import '../mybookings/css/mybookings.css';
import Table from 'react-bootstrap/Table';
const Mybookings = () => {
  return (
    <div className='my_bookings'>
      <Container>
        <div className='my_bookings_list'>
        <div className="global_geading"><p>Bookings</p><h2>My Bookings</h2></div>
        </div>

        <div className='table_list'>
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>S. No.</th>
          <th>Booking Id</th>
          <th>Booking Amount</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>#JKMNO</td>
          <td>$300</td>
          <td>Complete</td>
        </tr>
        <tr>
          <td>2</td>
          <td>#JKMNO</td>
          <td>$300</td>
          <td>Complete</td>
        </tr>
        <tr>
          <td>3</td>
          <td>#JKMNO</td>
          <td>$300</td>
          <td>Complete</td>
        </tr>

        <tr>
          <td>4</td>
          <td>#JKMNO</td>
          <td>$300</td>
          <td>Complete</td>
        </tr>

        <tr>
          <td>5</td>
          <td>#JKMNO</td>
          <td>$300</td>
          <td>Complete</td>
        </tr>

        
        <tr>
          <td>6</td>
          <td>#JKMNO</td>
          <td>$300</td>
          <td>Complete</td>
        </tr>

            
        <tr>
          <td>7</td>
          <td>#JKMNO</td>
          <td>$300</td>
          <td>Complete</td>
        </tr>

        <tr>
          <td>8</td>
          <td>#JKMNO</td>
          <td>$300</td>
          <td>Complete</td>
        </tr>


        <tr>
          <td>9</td>
          <td>#JKMNO</td>
          <td>$300</td>
          <td>Complete</td>
        </tr>
      </tbody>
    </Table>
        </div>
      </Container>
    </div>
  )
}

export default Mybookings
