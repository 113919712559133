
import { useEffect, useState } from 'react';

import Button from '@mui/material/Button';

import { Country } from 'country-state-city';
import { Container, Row, Col } from 'react-bootstrap';
import '../manageprofile/css/manageprofileresponcive.css';
import skin from '../../../assets/images/skin.png';
import skintwo from '../../../assets/images/skintwo.png';
import topcut from '../../../assets/images/topcrop.png';
import { apiBaseURL, getAllLocatData, encryptLocalStorageData, getCurrentDate, toastifyError, toastifySuccess } from '../../../components/Utility';
import { axiosInstance } from '../../../api/interceptor';
import axios from 'axios';
import "react-phone-input-2";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const Manageprofile = () => {
  const imgAvatarArr = [
    { name: skin, path: 'skin.png' },
    { name: skintwo, path: 'skintwo.png' }
  ];

  const [profileDetails, setProfileDetails] = useState({})
  const [findCountryCode, setFindCountryCode] = useState('au')
  const [countryName, setCountryName] = useState('Australia')
  const [languageArr, setLanguageArr] = useState([])
  const [langHide, setLangHide] = useState(false)


  const { getCode, getName } = require('country-list');
  const [value, setValue] = useState({
    first_name: '',
    last_name: '',
    prefix: 'Mr',
    dob: '',
    email: '',
    phone: '',
    country: '',
    language: '',
    language_level: '',
    avatar: 'image.png',

  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === 'country') {
      setCountryName(e.target.value);
    }
    setValue({
      ...value,
      [e.target.name]: e.target.value
    });
  };
  const onSelectAvatar = (item) => {
    console.log("Selected Avatar:", item.path);
    const updatedValue = {
      ...value,
      avatar: item.path
    };
    console.log("Updated Value:", updatedValue);
    setValue(updatedValue);
  };

  useEffect(() => {
    if (profileDetails?._id) {
      setValue({
        ...value,
        id: profileDetails?._id,
        prefix: profileDetails?.prefix,
        first_name: profileDetails?.first_name,
        last_name: profileDetails?.last_name,
        dob: profileDetails?.dob,
        email: profileDetails?.email,
        phone: profileDetails?.phone,
        country: profileDetails?.country,
        language: '',
        language_level: '',
        avatar: profileDetails?.avatar,
      })
      setLanguageArr(profileDetails?.languages)

    } else {
      setValue({
        ...value,
        first_name: '',
        last_name: '',
        prefix: 'Mr',
        dob: '',
        email: '',
        phone: '',
        country: '',
        language: '',
        language_level: '',
        avatar: 'image.png',

      })
    }
  }, [profileDetails])

  useEffect(() => {
    getProfileDeteails()
  }, [])

  const handleUpdate = async () => {
    setLoading(true);
    let params = languageArr
    if (value.language !== "" && value.language_level !== '') {
      const arr = { language: value.language, language_level: value.language_level }
      const langArr = [...languageArr, arr]
      params = { ...value, languages: langArr }
    } else {
      params = { ...value, languages: languageArr }
    }
    try {
      const headers = {
        'Authorization': `Bearer ${getAllLocatData('user')?.token}`
      };
      const res = await axios.post(`${apiBaseURL()}/user/updateProfile`, params, { headers });
      if (res.data.status === 200) {
        const dataParam = {
          token: getAllLocatData('user')?.token,
          login_id: res?.data?.userdata?._id,
          first_name: res?.data?.userdata?.first_name,
          last_name: res?.data?.userdata?.last_name,
          phone: res?.data?.userdata?.phone,
          email: res?.data?.userdata?.email,
          prefix: res?.data?.userdata?.prefix,

        };
        encryptLocalStorageData('gretenair-web-secret', dataParam, 'DoNotTryToAccess');

        setLoading(false);

        toastifySuccess(res.data.message || "Profile updated successfully");
      } else {
        setLoading(false);
        toastifyError(res.data.message || "Something went wrong while updating profile");
      }
    } catch (error) {
      console.error('Error:', error);
      toastifyError(error?.response?.data?.message || 'An error occurred while updating profile.');
      setLoading(false);
    }
  };


  const getProfileDeteails = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.post(`/user/userProfile`);
      if (res.status === 200) {
        setProfileDetails(res?.data?.user);
        setLoading(false);
      } else {
        setLoading(false);
        setProfileDetails(null);
      }
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  const addMoreLanguage = () => {
    if (!langHide) {
      setLangHide(true)
      return false
    }
    if (value.language == "" && value.language_level == "") {
      toastifyError("Please Select Language and Level")
      return false
    }
    const newData = {
      'language': value.language,
      'language_level': value.language_level,
    };
    setLanguageArr(prevValues => [...prevValues, newData]);
    setValue({ ...value, 'language': '', 'language_level': '' })
  }

  const removeLanguage = (index) => {
    const newItems = languageArr.filter((item, i) => i !== index);
    setLanguageArr(newItems);
  }

  const languageHideFun = () => {
    setLangHide(!langHide)
    setValue({ ...value, 'language': '', 'language_level': '' })
  }

  return (
    <div className="manageprofile">
      <Container>
        <div className="my_profile">
          <div className="global_geading">
            <p>Profile</p>
            <h2>Manage Profile</h2>
          </div>
        </div>

        <section className="about_field mt-5">
          <Row className="form">
            <Col md={4} className="mb-4">
              <div className="main_input prefix_input ">
                <div className='global_arrow half_arrow'>
                  <select name="prefix" onChange={handleChange}
                    value={value.prefix}
                  >
                    <option>Mr</option>
                    <option>Ms</option>
                    <option>Mrs</option>
                    <option>Miss</option>
                    <option>Mx</option>
                    <option>Doctor</option>
                    <option>Master</option>
                    <option>Captain</option>
                    <option>Dame</option>
                    <option>Judge</option>
                    <option>Lady</option>
                    <option>Lord</option>
                  </select>
                  <div className='cus_arrow'><i className="fa-solid fa-angle-down"></i></div>
                </div>
                <input
                  className=""
                  value={value.first_name}
                  name="first_name"
                  onChange={handleChange}
                  type="text"
                  placeholder="First name"
                />
              </div>
            </Col>
            <Col md={4} className="mb-4">
              <input value={value.last_name} name="last_name" onChange={handleChange} type="text" placeholder="Last name" />
            </Col>

            <Col md={4} className="mb-4">
              <div className="main_date">
                <label className="label_txt">Date of Birth</label>
                <input type="date" value={value.dob} name="dob" onChange={handleChange} placeholder="date"
                  max={getCurrentDate()}
                />
              </div>
            </Col>
            <Col md={4} className="mb-5">
              <input type="text" placeholder="Email " value={value.email} name="email" onChange={handleChange} />
            </Col>
            <Col md={4} className="mb-5">
              <PhoneInput
                country={findCountryCode} // Default country code
                enableSearch={true}
                value={value.phone} // Current phone value
                onChange={(phone, country) => {
                  // Extract country code from the selected country
                  setFindCountryCode(country ? country.countryCode : '');
                  setCountryName(getName(country ? country.countryCode : ''));
                  // Update phone value in state
                  setValue({ ...value, phone });
                }} // onChange handler
                inputProps={{
                  name: 'phone',
                  className: 'pl-5',
                  //className: checkError(error.phone) ? 'error_outline' : '',
                  placeholder: 'Mobile Number',
                }} // Input properties
              />

            </Col>
            <Col md={4} className="mb-5">
              <div className='global_arrow'>
                <select value={countryName} name="country" onChange={handleChange} className="w-100">
                  <option value="">Select Country</option>
                  {Country?.getAllCountries()?.map((item, i) => (
                    <option value={item.name} key={i}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <div className='cus_arrow'><i className="fa-solid fa-angle-down"></i></div>
              </div>
            </Col>
            <Col md={12}>
              <Row>


                <Col md={12}>
                  {
                    languageArr?.map((data, i) => (
                      <>
                        <Row className='justify-content-end'>
                          <Col md={6} className="mb-4">
                            <div className="global_arrow">
                              <select
                                value={data.language}
                                name="language"
                                onChange={handleChange}
                                className="w-100"
                                disabled
                              >
                                <option value="">Select Language</option>
                                <option>English</option>
                                <option>Mandarin</option>
                                <option>French</option>
                                <option>Spanish</option>
                                <option>Portuguese</option>
                                <option>Hindi</option>
                                <option>Japanese</option>
                                <option>Vietnamese</option>
                                <option>Korean</option>
                                <option>Urdu</option>
                                <option>Italian</option>
                                <option>Turkic</option>
                                <option>Sino-Tibetan</option>
                                <option>Indo-European</option>
                                <option>Russian</option>
                                <option>Javanese</option>
                                <option>Yue Chinese</option>
                                <option>Egyptian Arabic</option>
                                <option>Swahili </option>
                                <option>Indonesian</option>
                                <option>Polish</option>
                              </select>
                              <div className="cus_arrow">
                                <i className="fa-solid fa-angle-down"></i>
                              </div>
                            </div>
                          </Col>
                          <Col md={6} className="mb-2">
                            <div className="global_arrow">
                              <select
                                value={data.language_level}
                                name="language_level"
                                onChange={handleChange}
                                className="w-100"
                                disabled
                              >
                                <option value="">Select Language</option>
                                <option>Native</option>
                                <option>Fluent</option>
                                <option>Competent</option>
                                <option>Elementary</option>
                                <option>Learning</option>
                              </select>
                              <div className="cus_arrow">
                                <i className="fa-solid fa-angle-down"></i>
                              </div>
                            </div>
                          </Col>
                          {
                            languageArr?.length > 1 &&
                            <Col md={2} className="mb-2 text-end">
                              <button className="text-danger bg-white m-0 border-0" onClick={() => removeLanguage(i)}> <i class="fa-regular fa-trash-can"></i></button>
                            </Col>
                          }
                        </Row>
                      </>
                    ))
                  }
                </Col>


                {
                  langHide && <>
                    <Col md={6} className="mb-4">
                      <div className='global_arrow'>
                        <select value={value.language} name="language" onChange={handleChange} className="w-100">
                          <option value="">Select Language</option>
                          <option>English</option>
                          <option>Mandarin</option>
                          <option>French</option>
                          <option>Spanish</option>
                          <option>Portuguese</option>
                          <option>Hindi</option>
                          <option>Japanese</option>
                          <option>Vietnamese</option>
                          <option>Korean</option>
                          <option>Urdu</option>
                          <option>Italian</option>
                          <option>Turkic</option>
                          <option>Sino-Tibetan</option>
                          <option>Indo-European</option>
                          <option>Russian</option>
                          <option>Javanese</option>
                          <option>Yue Chinese</option>
                          <option>Egyptian Arabic</option>
                          <option>Swahili </option>
                          <option>Indonesian</option>
                          <option>Polish</option>
                        </select>
                        <div className='cus_arrow'><i className="fa-solid fa-angle-down"></i></div>
                      </div>
                    </Col>
                    <Col md={6} className="mb-4">
                      <div className='global_arrow'>
                        <select value={value.language_level} name="language_level" onChange={handleChange} className="w-100">
                          <option value="">Language Level</option>
                          <option>Native</option>
                          <option>Fluent</option>
                          <option>Competent</option>
                          <option>Elementary</option>
                          <option>Learning</option>
                        </select>
                        <div className='cus_arrow'><i className="fa-solid fa-angle-down"></i></div>
                      </div>
                    </Col>
                    <Col md={12} className="mb-2 text-end">
                      {
                        (languageArr?.length > 0 && langHide) &&
                        <button className=" dlt_btn_add text-danger bg-white m-0 border-0" onClick={() => languageHideFun()}><i class="fa-regular fa-trash-can"></i></button>
                      }
                    </Col>
                  </>
                }

                <div className="mb-3 text-end">
                  <button type='button' className="add_btn border-0 m-0" onClick={() => addMoreLanguage()}><i class="fa-solid fa-plus me-2"></i> Add More Language </button>
                </div>


              </Row>
            </Col>

          </Row>

          <Row>
            <Col md={12} className="text-end">
              {
                loading ? <>
                  <Button className="button1" sx={{ mr: 1 }} >
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                  </Button>
                </>
                  :
                  <button className="button1 border-0" onClick={handleUpdate}>
                    update
                  </button>
              }
            </Col>

          </Row>
        </section>
      </Container>
    </div>
  );
};

export default Manageprofile;
