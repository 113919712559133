import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../front/boardflight/boardflight.css";
import Footer from "../../front/include/Footer";
import scheck from "../../../assets/images/check.gif";
import board from "../../../assets/images/board.gif";
import { Link } from "react-router-dom";
import BackBTN from "../../../components/BackBTN";
const Boarding = () => {
  return (
    <div className="boarding">
      <div className="log_in_page flight_board">
        <Container fluid>
          <Row className="main align-items-center justify-content-center">
          <Col md={6} lg={4}>
              <div className="main_outer_global_box">
                <div className="box_heading">
                  Bording on flight please wait...
                </div
                >
                <BackBTN/>
                <div className="global_bg_box text-center">
                  <div className="securtiy_checkbox text-center">
                    <img src={board} alt="securitychck" />
                  </div>
                  <h2>Boarding for [Flight number] ....</h2>
                  <p>
                    Thank you for your attention, and we wish you a pleasant
                    flight.
                  </p>

                  <Link to="/wherewanttogo" className="button1">
                    {" "}
                    Go
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Boarding;
