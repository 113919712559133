import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import footerlogo from "../../../assets/images/footerlogo.png";
import { Link } from "react-router-dom";
import { Offcanvas } from "react-bootstrap";
import arrowimg from "../../../assets/images/footerarrow.png";
// import "./Footer.css"; // Import your CSS file

const Footer = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleClose = () => {
    setShowMenu(false);
    document.body.classList.remove("modal-open");
  };
  const handleShow = () => setShowMenu(true);
  const handleOpen = () => {
    setShowMenu(!showMenu);
    if (!showMenu) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  };

  return (
    <div>
      <footer className={showMenu ? "add_new" : ""}>
        <Container>
          <Row className="justify-content-center align-items-center">
            <div classNmae="cr_btn">
              <button
                //  className="pulsating-circle"
                className={showMenu ? "pulsating-circle" : "pulsating-circle rotate-180"}
                onClick={handleOpen}
              >
                <img src={arrowimg} alt="arrow img" />
              </button>
            </div>

            <Col md={4} className="text-start">
              <div className="footer_logo p-2">
                <Link to="/">
                  {" "}
                  <img src={footerlogo} alt="footer logo" />
                </Link>
              </div>
            </Col>

            <Col md={4} className="text-center">
              <p className="text-white m-0">
              Copyright © 2024 GretenAir. All Rights Reserved.{" "}
              </p>
            </Col>

            <Col md={4} className="text-end">
              <div class="social-icon">
                <ul class="m-0 p-0 justify-content-end">
                  <li>
                    <a href="/signup">
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/signup">
                      <i class="fa-brands fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href="/signup">
                      <i class="fa-brands fa-x-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <Offcanvas show={showMenu} onHide={handleClose} placement="bottom">
          <Offcanvas.Body className="footer_canvas">
            <Container>
              <Row className="justify-content-center">
                <Col md={6} className="mb-5">
                  <div className="logo_footer">
                    <img src={footerlogo} alt="" />
                  </div>
                </Col>

                <Col md={6} lg={2} className="mb-3">
                  <ul className="p-0">
                    <li>
                      <Link to="">Navigation</Link>
                    </li>

                    <li>
                      <Link to="">Home</Link>
                    </li>
                  </ul>
                </Col>

                <Col md={6} lg={2} className="mb-3">
                  <ul className="p-0">
                    <li>
                      <Link to="">About</Link>
                    </li>

                    <li>
                      <Link to="">Contact Us</Link>
                    </li>
                  </ul>
                </Col>

                <Col md={6} lg={2} className="mb-3">
                  <ul className="p-0">
                    <li>
                      <Link to="">Legal</Link>
                    </li>

                    <li>
                      <Link to="">Privacy Policy</Link>
                    </li>

                    <li>
                      <Link to="">Terms</Link>
                    </li>
                    <div className="social-icon">
                      <ul className="m-0 p-0">
                        <li>
                          <Link>
                            <i className="fa-brands fa-facebook-f"></i>
                          </Link>
                        </li>
                        <li>
                          <Link>
                            <i className="fa-brands fa-youtube"></i>
                          </Link>
                        </li>
                        <li>
                          <Link>
                            <i className="fa-brands fa-x-twitter"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </ul>
                </Col>
              </Row>
            </Container>
          </Offcanvas.Body>
        </Offcanvas>
      </footer>
    </div>
  );
};

export default Footer;
