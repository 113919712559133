import "./App.css";
import Home from "./panel/front/home/Home";

import React, { useState, useEffect } from 'react';
// import Header from './panel/front/include/Header';
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, Navigate } from "react-router-dom";
// import Footer from './panel/front/include/Footer';
import Login from "./panel/auth/user/Login";
import WebRoute from "./layout/WebRoute";
import Regsiter from "./panel/auth/user/Regsiter";
import Flightlist from "./panel/front/flightlist/Flightlist";
import Booking from "./panel/front/booking/Booking";
import Boardflight from "./panel/front/boardflight/Boardflight";
import Shop from "./panel/front/shop/Shop";
import Boarding from "./panel/front/checkin/Boarding";
import Optionchoose from "./panel/front/checkin/Optionchoose";
import Chat from "./panel/front/chat/Chat";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Mybookings from "./panel/front/mybookings/Mybookings";
import Manageprofile from "./panel/front/manageprofile/Manageprofile";
import "aos/dist/aos.css";
import AOS from "aos";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import AdminLayout from "../src/layout/admin/Admin";
import AuthLayout from "../src/layout/admin/Auth";
import { useLocation } from "react-router-dom";
import { Loader } from "./components/Loader";

function App() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [currentPath]);
  useEffect(() => {
    AOS.init();
  }, []);
  return (

    <>
      {
        loading ? <>
          <Loader />
        </>
          :
          <>
            <Routes>
              <Route path="/" element={<WebRoute cmp={Home} />} />
              <Route path="/Login" Component={Login} />
              <Route path="/signup" Component={Regsiter} />
              <Route path="/takeyourflight" Component={Boardflight} />
              <Route path="/inquiryandshop" Component={Shop} />
              <Route path="/boardingflight" Component={Boarding} />
              <Route path="/wherewanttogo" Component={Optionchoose} />
              <Route path="/mybookings" element={<WebRoute cmp={Mybookings} />} />
              <Route path="/manageprofile" element={<WebRoute cmp={Manageprofile} />} />
              <Route path="/chat" Component={Chat} />
              {/* <Route path='flightlist' Component={Flightlist} /> */}
              <Route path="/flightlist" element={<WebRoute cmp={Flightlist} />} />
              <Route path="/booking" element={<WebRoute cmp={Booking} />} />

              <Route path="/admin/*" element={<AdminLayout />} />
              <Route path="/auth/*" element={<AuthLayout />} />
              <Route path="*" element={<Navigate to="/admin/index" replace />} />

            </Routes>
            <ToastContainer />

          </>
      }
    </>
  );
}

export default App;
