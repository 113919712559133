import { useState, useEffect } from 'react';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from 'react-router-dom';
import Dropdown from "react-bootstrap/Dropdown";
import navlogo from "../../../assets/images/Gretenairlogo.png";
import defalutuserimg from "../../../assets/images/defalutuser.jpg";
import usericon from "../../../assets/images/user.png";
import bookicon from "../../../assets/images/writing.png";
import logout from "../../../assets/images/logout.png";
import Button from '@mui/material/Button';

import { Link } from "react-router-dom";
import "../include/Common.css";
import "../include/CommonResponsive.css";
import { auth, getAllLocatData, toastifySuccess } from '../../../components/Utility';
console.log(getAllLocatData('user'),'data')
const Header = () => {

  const [scrolling, setScrolling] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = () => {

    //setLoading(false);
   
   
    setTimeout(() => {
      localStorage.removeItem('gretenair-web-secret');
      setLoading(true); 
      
      
      navigate('/login');
      toastifySuccess("Log Out successfully !!")
    }, 1000); // Adjust delay
   
  };

  return (
    <header className={`front_design your-header-class ${scrolling ? 'scrolled' : ''}`}>

      <Navbar expand="lg" className="">
        <Container>
          <Link to="/">
            <div className="nav_logo">
              <img src={navlogo} alt="nav logo" />
            </div>
          </Link>

          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            ></Nav>
            <Link className="button1 me-2" to="/takeyourflight">
              Take Your Flight
            </Link>
            
            {getAllLocatData('user') ? (
              <Dropdown className="login_dropdown">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={defalutuserimg} alt="nav logo" />
                  
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <ul className="p-0 profile_drodwn">
                    <li>
                      <Link to="">
                        <div className="profile_user">
                          <div className="profile_img">
                            <img src={defalutuserimg} alt="defalut user" />
                          </div>
                          <div className="user_name text-capitalize">{getAllLocatData('user')?.first_name} {""}{getAllLocatData('user')?.last_name}</div>
                        </div>
                      </Link>
                    </li>
                    <hr />
                    <li>
                      <Link to="/manageprofile">
                        <div className="icon">
                          <img src={usericon} alt="" />
                        </div>
                        <div>My Account</div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/mybookings">
                        <div className="icon">
                          <img src={bookicon} alt="" />
                        </div>
                        <div>My Bookings</div>
                      </Link>
                    </li>
                    <hr />
                    <li>
                      <Link to="" onClick={handleLogout}>
                        <div className="icon">
                          <img src={logout} alt="" />
                        </div>
                        <div>Logout</div>
                      </Link>
                    </li>
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Link className="button1 me-2" to="/login">
                Log in
              </Link>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
