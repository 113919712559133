import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Make sure you import from 'react-router-dom'
import axios from "axios";
import CryptoJS from "crypto-js";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap"; // Assuming you're using reactstrap for UI components
import {
  encryptLocalStorageData,
  toastifyError,
  toastifySuccess,
} from "../../../components/Utility"; // Make sure you import these utility functions correctly
import config from "../../../config"; // Make sure you import the config correctly
import navlogo from "../../../assets/images/Gretenairlogo.png";
const Login = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const [value, setValue] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    email: "",
    password: "",
  });
  const encryptData = (data, key) => {
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      key
    ).toString();
    return ciphertext;
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });

    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!value.email) {
      isValid = false;
      newErrors.email = "Please enter your Username/email.";
    } else if (!/\S+@\S+\.\S+/.test(value.email)) {
      isValid = false;
      newErrors.email = "Please enter a valid email address.";
    }

    if (!value.password) {
      isValid = false;
      newErrors.password = "Please enter your password.";
    }

    setError(newErrors);
    return isValid;
  };
  // Function to handle Remember Me checkbox change
  const handleRememberMeChange = () => {
    setRememberMe((prevRememberMe) => !prevRememberMe);
  };
  // Populate form fields with remembered credentials on component mount
  useEffect(() => {
    const rememberedCredentials = JSON.parse(
      localStorage.getItem("rememberedCredentials")
    );
    if (rememberedCredentials) {
      setValue({
        email: rememberedCredentials.email,
        password: rememberedCredentials.password,
      });
      setRememberMe(true);
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      const res = await axios.post(`${config.url}/admin/loginAdmin`, value);
      if (res.status === 200) {
        if (rememberMe) {
          // If Remember Me is checked, store email and password in localStorage
          const encryptedCredentials = encryptData(value, "yourCryptoKey");
          localStorage.setItem("rememberedCredentials", encryptedCredentials);
        } else {
          // If Remember Me is unchecked, remove remembered credentials from localStorage
          localStorage.removeItem("rememberedCredentials");
        }

        const dataParam = {
          token: res?.data.token,
          login_id: res?.data.userdata._id,
          first_name: res?.data.userdata.first_name,
          last_name: res?.data.userdata.last_name,
          email: res?.data.userdata.email,
          role: res?.data.role,
        };

        encryptLocalStorageData(
          "gretenair-admin-secret",
          dataParam,
          "DoNotTryToAccess"
        );
        setTimeout(() => {
          toastifySuccess("Login successfully !!");
          setLoading(false);
          navigate("/admin/UserList");
        }, 2000);
      } else {
        setLoading(false);
        toastifyError("Something went wrong!");
      }
    } catch (error) {
      setLoading(false);
      toastifyError(
        error?.response?.data?.message || "An error occurred while logging in."
      );
    }
  };

  return (
    <Col lg="5" md="7">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          <div className="logo_site text-center mb-4">
            {" "}
            <Link to="">
              {" "}
              <img
                src={navlogo}
                alt="nav logo"
                width={200}
                style={{ margin: "auto" }}
              />
            </Link>
          </div>
          <Form role="form" onKeyDown={handleKeyDown}>
            <FormGroup className="mb-3">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  autoComplete="email"
                  placeholder="Email"
                  type="email"
                  value={value.email}
                  onChange={handleChange}
                  name="email"
                  className={error.email && "error_outline"}
                />
              </InputGroup>
              {error.email && (
                <span className="text-danger">{error.email}</span>
              )}
            </FormGroup>
            <FormGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i
                      className={`${
                        showPassword
                          ? "fa-solid fa-lock-open"
                          : "fa-solid fa-lock"
                      }`}
                      onClick={togglePasswordVisibility}
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  // type={rememberMe ? "password" : (showPassword ? "text" : "password")}
                  value={value.password}
                  onChange={handleChange}
                  autoComplete="password"
                  name="password"
                  className={error.password && "error_outline"}
                />
              </InputGroup>
              {error.password && (
                <span className="text-danger">{error.password}</span>
              )}
            </FormGroup>
            <div className="custom-control custom-control-alternative custom-checkbox">
              <input
                className="custom-control-input"
                id="customCheckLogin"
                type="checkbox"
                checked={rememberMe} // Bind checked state to Remember Me checkbox
                onChange={handleRememberMeChange} // Handle change event of Remember Me checkbox
              />
              <label
                className="custom-control-label"
                htmlFor="customCheckLogin"
              >
                <span className="text-muted">Remember me</span>
              </label>
            </div>
            <div className="text-center">
              {loading ? (
                <Button
                  className="my-4 button1  border-0"
                  type="button"
                  onClick={handleLogin}
                >
                  Log in
                  <div class="spinner-border text-light " role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </Button>
              ) : (
                <Button
                  className="my-4 button1  border-0"
                  type="button"
                  onClick={handleLogin}
                >
                  Log in
                </Button>
              )}
            </div>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Login;
