import  { useState} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../../auth/user/css/login.css';
import Footer from '../../front/include/Footer';
import logo from '../../../assets/images/footerlogo.png';
import { Link } from 'react-router-dom';
import BackBTN from '../../../components/BackBTN';
import Button from '@mui/material/Button';
import config from '../../../config';
import { encryptLocalStorageData, toastifyError, toastifySuccess } from '../../../components/Utility';
import axios from 'axios';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [messageSent] = useState(false);
  const [value, setValue] = useState({
    email: '',
    password: ''
  });

  const [error, setError] = useState({
    email: '',
    password: ''
  });
  
  const navigate = useNavigate();
  const handleChange = (e) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value
    });

    setError({
      ...error,
      [e.target.name]: ''
    });
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (!value.email) {
      isValid = false;
      newErrors.email = 'Please enter your Username/Email.';
    } else if (!/\S+@\S+\.\S+/.test(value.email)) {
      isValid = false;
      newErrors.email = 'Please enter a valid Email address.';
    }

    if (!value.password) {
      isValid = false;
      newErrors.password = 'Please enter your Password.';
    }

    setError(newErrors);
    return isValid;
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default form submission
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (!validateForm()) {
      return;
    }
    setLoading(true)
    try {
      const res = await axios.post(`${config.url}/user/loginUser`, value)
      if (res.status) {
        const dataParam = {
          token: res?.data.token,
          login_id: res?.data?.userdata?._id,
          first_name: res?.data?.userdata?.first_name,
          last_name: res?.data?.userdata?.last_name,
          phone: res?.data?.userdata?.phone,
          email: res?.data?.userdata?.email,
          prefix: res?.data?.userdata?.prefix,
          role: "user",
        };
        
        encryptLocalStorageData('gretenair-web-secret', dataParam, 'DoNotTryToAccess');
        setTimeout(() => {
          toastifySuccess("Login successfully !!")
          setLoading(false)
          navigate('/');
        }, 2000);
      } else {
        setLoading(false)
        toastifyError("Something wen't wrong !!")
      }
    } catch (error) {
      toastifyError(error?.response?.data?.message)
      setLoading(false)
    }
  }


  const checkError = (field) => {
    if(field == "ok"){
      return true
    }else{
      return false
    }
  }

  return (
    <div className="login_user">
      {/* <Header  isLoggedIn={isLoggedIn} /> */}
      <div className="log_in_page">
        <Container fluid>
          <Row className="main align-items-center justify-content-center">
            <Col md={6} lg={4}>
              <div className="main_outer_global_box slideDown">
                <div className="box_heading">Log in to your account</div>
                {/* <button className="back_btn" onClick={handleback}><i class="fa-solid fa-arrow-left"></i></button> */}
                <BackBTN />
                <div className="global_bg_box">
                  <div className="logo_box text-center">
                    <Link to="/">
                      {' '}
                      <img src={logo} alt="logo" />
                    </Link>{' '}
                  </div>
                  {messageSent ? (
                    <div className="success-message">Login successful!</div>
                  ) : (
                    <div className="form" onKeyDown={handleKeyDown}>
                      <Row>
                        <Col md={12} className="mb-4">
                          <input type="text" value={value.email} name="email" onChange={handleChange} placeholder="Username / Email"        className={checkError(error.email) && 'error_outline'} />
                          {error.email && <span className="errorMsg mt-2 d-block">{error.email}</span>} 
                        </Col>
                        <Col md={12} className="mb-4">
                          <input type="password" value={value.password} name="password" onChange={handleChange} placeholder="Password"        className={checkError(error.password) && 'error_outline'} />
                          {error.password && <span className="errorMsg mt-2 d-block">{error.password}</span>} 

                        </Col>

                        <Col md={12} className="mb-1">
                          {loading ? (
                            <>
                              <Button className="button1 w-100" sx={{ mr: 1 }}>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                              </Button>
                            </>
                          ) : (
                            <Button className="button1 w-100" type="submit" onClick={(event) => handleLogin(event)} sx={{ mr: 1 }}>
                              Login
                            </Button>
                          )}
                        </Col>

                        <Col md={12} className="mb-4 text-end">
                          <Link className="w-100 mt-2 d-block">Forgot Password</Link>
                        </Col>

                        <Col md={12} className="mb-4 text-center">
                          <p className="m-0">
                            Don’t have an account yet ?{' '}
                            <span className="highlight_text">
                              <Link to="/signup">Sign Up</Link>
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
