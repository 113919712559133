import React, { useState, useEffect } from 'react';


import Header from "../panel/front/include/Header";
import Footer from "../panel/front/include/Footer";
import { Navigate, useLocation } from "react-router-dom";
import { auth, getAllLocatData } from "../components/Utility";
import { Loader } from "../components/Loader";

const WebRoute = ({ cmp }) => {


  const Component = cmp;
  const location = useLocation();
  const currentPath = location.pathname;
  //console.log(currentPath,'path')
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [currentPath]);

  if (location.pathname === "/manageprofile") {
    if (!getAllLocatData('user')) {
      return <Navigate to="/login" />;
    }
  }
  return (


    <>
      {
        loading ? <>
          <Loader />
        </>
          :
          <>
            <Header />
            <Component />
            <Footer />

          </>
      }
    </>
  );
};

export default WebRoute;
