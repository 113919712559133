import  { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from "react-bootstrap/DropdownButton";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../../panel/front/flightlist/flightlist.css";
import logoflight from '../../../assets/images/logoflight.png'
import flightway from '../../../assets/images/flightfrom.png'
import topcut from '../../../assets/images/topcrop.png'
const Flightlist = () => {
  const [quantity, setQuantity] = useState(1);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    // You can add any logic for maximum quantity if needed
    setQuantity(quantity + 1);
  };
  return (
    <div className="flight_list">
      <section className="ticketbook">
        <Container>
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="Home">
            <Row>
                <Col md={6}
                  lg={4} className="mb-3">
                  <div className="form_search_date">
                    <div className="flight_Search_boxed date_flex_area">
                      <div className="Journey_date">
                        <p>Journey date</p>
                        <input type="date" />
                        <span>Thursday</span>
                      </div>

                      <div className="retundate">
                        <p>Return date</p>
                        <input type="date" />
                        <span>Thursday</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  md={6}
                  lg={3}
                  className="mb-3"
               
                >
                  <div className="flight_Search_boxed">
                    <p>From</p>
                    <input type="text" placeholder="Where You Want To Go" />
                    <span>JFK - John F. Kennedy International...</span>
                    <div className="plan_icon_posation">
                      <i className="fas fa-plane-departure"></i>
                    </div>
                  </div>
                </Col>
                <Col
                  md={6}
                  lg={3}
                  className="mb-3"
        
                >
                  <div className="flight_Search_boxed">
                    <p>To</p>
                    <input type="text" placeholder="Where You Want To Go" />
                    <span>DXB-Dubai</span>
                    <div className="plan_icon_posation">
                      <i className="fa-solid fa-plane-arrival"></i>
                    </div>
                    <div className="range_plan">
                      <i className="fas fa-exchange-alt"></i>
                    </div>
                  </div>
                </Col>

                <Col
                  md={6}
                  lg={2}
                  className="mb-3 outer_for_overlay"
        
                >
                  <div className="flight_Search_boxed dropdown_passenger_area">
                    <p>Passenger, Class </p>
                    <div className="dropdown">
                      <DropdownButton
                        id="dropdown-basic-button"
                        title="0 Passenger"
                      >
                        <div className="traveller-calulate-persons">
                          <div className="passengers">
                            <h6>Passengers</h6>
                            <div className="passengers-types">
                              <div className="passengers-type">
                                <div className="text">
                                  <span className="count pcount">{quantity}</span>
                                  <div className="type-label">
                                    <p>Adult</p>
                                    <span>12+ yrs</span>
                                  </div>
                                </div>
                                <div className="quantity_select">
                                  <button onClick={decreaseQuantity}>-</button>

                                  <button onClick={increaseQuantity}>+</button>
                                </div>
                              </div>
                              <div className="passengers-type">
                                <div className="text">
                                  <span className="count ccount">{quantity}</span>
                                  <div className="type-label">
                                    <p className="fz14 mb-xs-0">Children</p>
                                    <span>2 - Less than 12 yrs</span>
                                  </div>
                                </div>
                                <div className="quantity_select">
                                  <button onClick={decreaseQuantity}>-</button>

                                  <button onClick={increaseQuantity}>+</button>
                                </div>
                              </div>
                              <div className="passengers-type">
                                <div className="text">
                                  <span className="count incount">{quantity}</span>
                                  <div className="type-label">
                                    <p className="fz14 mb-xs-0">Infant</p>
                                    <span>Less than 2 yrs</span>
                                  </div>
                                </div>
                                <div className="quantity_select">
                                  <button onClick={decreaseQuantity}>-</button>

                                  <button onClick={increaseQuantity}>+</button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="cabin-selection">
                            <h6>Cabin class</h6>
                            <div className="cabin-list">
                              <button type="button" className="label-select-btn">
                                <span className="muiButton-label">Economy</span>
                              </button>
                              <button
                                type="button"
                                className="label-select-btn active"
                              >
                                <span className="muiButton-label">Business</span>
                              </button>
                              <button type="button" className="label-select-btn">
                                <span className="MuiButton-label">
                                  First Class{" "}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </DropdownButton>
                      <div
                        className="dropdown-menu dropdown_passenger_info"
                        aria-labelledby="dropdownMenuButton1"
                      ></div>
                    </div>
                    <span>Business</span>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="search_btn">
                    <Link className="button1" to="/flightlist">
                      Search
                    </Link>
                  </div>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="profile" title="Profile">
              Tab content for Profile
            </Tab>
          </Tabs>
        </Container>
      </section>

      <section className="flight_listings">
        <Container>
          <div className="sf">Search Result</div>
          <Row className="justify-content-center align-items-center">
            <Col md={12} lg={12} xl={11} className="mb-5">
              <div className="inner_flight_box">
                <Row className="align-items-center">
                <Col lg={2}>
                 <div className="logo_airline"> <img className="w-100" src={logoflight} alt="flight logo"/></div>
                </Col>
                  <Col lg={4}>
                   <div className="flight_box_main">
                   <div className="flight_from">
                      <p>From</p>
                      <h2>New York</h2>
                      <p>LCY, London city airport</p>
                    </div>
                    <div className="aeeroplan"><img src={flightway} alt="flight way"/></div>
                    <div className="flight_from flight_to">
                      <p>To</p>
                      <h2>Sydney</h2>
                      <p>LCY, London city airport</p>
                    </div>
                   </div>
                  </Col>
                  <Col lg={4}>
                   <div className="flight_box_main return_box">
                   <div className="flight_from">
                      <p>From</p>
                      <h2>Sydney</h2>
                      <p>LCY, London city airport</p>
                    </div>
                    <div className="aeeroplan"><img src={flightway} alt="flight way"/></div>
                    <div className="flight_from flight_to">
                      <p>To</p>
                      <h2>New York</h2>
                      <p>LCY, London city airport</p>
                    </div>
                   </div>
                  </Col>

                  <Col lg={2}>
                <div className="text-center">
                <div className="price mb-3">$730</div>
                 <Link to="/booking" className="button1">Book</Link>
                </div>
                </Col>
                </Row>
                <div className="top_cut">
                  <img src={topcut} alt=""/>
                </div>
                <div className="bottum_cut">
                  <img src={topcut} alt=""/>
                </div>
              </div>
            </Col>

            <Col md={12} lg={12} xl={11} className="mb-5">
              <div className="inner_flight_box">
                <Row className="align-items-center">
                <Col lg={2}>
                 <div className="logo_airline"> <img className="w-100" src={logoflight} alt="flight logo"/></div>
                </Col>
                  <Col lg={4}>
                   <div className="flight_box_main">
                   <div className="flight_from">
                      <p>From</p>
                      <h2>New York</h2>
                      <p>LCY, London city airport</p>
                    </div>
                    <div className="aeeroplan"><img src={flightway} alt="flight way"/></div>
                    <div className="flight_from flight_to">
                      <p>To</p>
                      <h2>Sydney</h2>
                      <p>LCY, London city airport</p>
                    </div>
                   </div>
                  </Col>
                  <Col lg={4}>
                   <div className="flight_box_main return_box">
                   <div className="flight_from">
                      <p>From</p>
                      <h2>Sydney</h2>
                      <p>LCY, London city airport</p>
                    </div>
                    <div className="aeeroplan"><img src={flightway} alt="flight way"/></div>
                    <div className="flight_from flight_to">
                      <p>To</p>
                      <h2>New York</h2>
                      <p>LCY, London city airport</p>
                    </div>
                   </div>
                  </Col>

                  <Col lg={2}>
                <div className="text-center">
                <div className="price mb-3">$730</div>
                 <Link to="/booking" className="button1">Book</Link>
                </div>
                </Col>
                </Row>
                <div className="top_cut">
                  <img src={topcut} alt=""/>
                </div>
                <div className="bottum_cut">
                  <img src={topcut} alt=""/>
                </div>
              </div>
            </Col>

            <Col md={12} lg={12} xl={11} className="mb-5">
              <div className="inner_flight_box">
                <Row className="align-items-center">
                <Col lg={2}>
                 <div className="logo_airline"> <img className="w-100" src={logoflight} alt="flight logo"/></div>
                </Col>
                  <Col lg={4}>
                   <div className="flight_box_main">
                   <div className="flight_from">
                      <p>From</p>
                      <h2>New York</h2>
                      <p>LCY, London city airport</p>
                    </div>
                    <div className="aeeroplan"><img src={flightway} alt="flight way"/></div>
                    <div className="flight_from flight_to">
                      <p>To</p>
                      <h2>Sydney</h2>
                      <p>LCY, London city airport</p>
                    </div>
                   </div>
                  </Col>
                  <Col lg={4}>
                   <div className="flight_box_main return_box">
                   <div className="flight_from">
                      <p>From</p>
                      <h2>Sydney</h2>
                      <p>LCY, London city airport</p>
                    </div>
                    <div className="aeeroplan"><img src={flightway} alt="flight way"/></div>
                    <div className="flight_from flight_to">
                      <p>To</p>
                      <h2>New York</h2>
                      <p>LCY, London city airport</p>
                    </div>
                   </div>
                  </Col>

                  <Col lg={2}>
                <div className="text-center">
                <div className="price mb-3">$730</div>
                 <Link to="/booking" className="button1">Book</Link>
                </div>
                </Col>
                </Row>
                <div className="top_cut">
                  <img src={topcut} alt=""/>
                </div>
                <div className="bottum_cut">
                  <img src={topcut} alt=""/>
                </div>
              </div>
            </Col>

          

       
         
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Flightlist;
