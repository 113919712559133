import { toast } from 'react-toastify';
var CryptoJS = require('crypto-js');


export const apiBaseURL = () => {
    return "https://gretenair.itworkshop.in"
}

// export const auth = () => {
//     if (getAllLocatData()?.token) return true; else return false
// }


export const updateTokenInLocalStorage = (newToken) => {
    const userData = getAllLocatData();
    if (userData) {
        userData.token = newToken;
        encryptLocalStorageData('gretenair-web-secret', userData, 'DoNotTryToAccess');
    }
};

export const toastifySuccess = (message) => {
    toast.success(`${message}`, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
    });
};

export const toastifyError = (message) => {
    toast.error(`${message}`, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark'
    });
};

export const encryptLocalStorageData = (name, data, key) => {
    var encryptData = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    localStorage.setItem(name, encryptData);
};

export const dycryptLocalStorageData = (encryptData, key) => {
    var bytes = CryptoJS.AES.decrypt(encryptData, key);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

export const getAllLocatData = (role) => {
    const key = role === 'user' ? 'gretenair-web-secret' : 'gretenair-admin-secret'
    console.log(key,'key')
    if (localStorage.getItem(key)) return dycryptLocalStorageData(localStorage.getItem(key), 'DoNotTryToAccess');
    else {
        return null;
    }
};


export const getCurrentDate = () => {
    const today = new Date();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${today.getFullYear()}-${month}-${day}`;
  };