import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../../front/shop/shop.css";
import "../../front/shop/css/shopresponive.css";
import Footer from "../../front/include/Footer";
import sendicon from "../../../assets/images/send.png";
import product from "../../../assets/images/burger.jpg";
import producttwo from "../../../assets/images/sandvich.webp";
import productthree from "../../../assets/images/coffe.png";

import { Link } from "react-router-dom";

const Shop = () => {
  // const [showText, setShowText] = useState(false);

  // const toggleText = () => {
  //   setShowText((prevState) => !prevState);
  // };

  const [showText, setShowText] = useState(false);

  const toggleText = () => {
    setShowText((prevState) => !prevState);
  };

  return (
    <div className="shop_and_inquiry">
      <div className="toggle_circle">
        {/* Your content for the text */}

        <button onClick={toggleText}>
          <i className="fa-solid fa-comments"></i>
        </button>
      </div>
      <div className="log_in_page shop_inq">
        <Container>
          <Row className="main align-items-center justify-content-center">
            <Col lg={4}>
              <div
                className={
                  showText
                    ? "text-visible"
                    : "text-hidden main_outer_global_box chat_box"
                }
              >
                <div className="box_heading">inquiry</div>
                <div className="shop global_bg_box text-center">
                  <div className="product_list chat_list"></div>

                  <div className="wallet_greten_air">
                    <div className="form w-100">
                      <input
                        className="w-100"
                        type="text"
                        placeholder="send message..."
                      />
                    </div>
                    <div>
                      <button className="button1 m-0">
                        <img src={sendicon} alt="send icon" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div
                className={
                  showText ? "hidedivemobile" : "main_outer_global_box"
                }
              >
                <div className="box_heading">Shop</div>
                <div className="shop global_bg_box text-center">
                  <div className="product_list">
                    <Row>
                      <Col md={6} lg={4} xl={3} className="mb-3">
                        <div className="product_box">
                          <div className="product_img">
                            <img src={product} alt="productimg" />
                          </div>
                          <div className="productName">Burger</div>
                          <div className="productprice">$20</div>
                          <div>
                            <button className="button1">Puchase</button>
                          </div>
                        </div>
                      </Col>

                      <Col md={6} lg={4} xl={3} className="mb-3">
                        <div className="product_box">
                          <div className="product_img">
                            <img src={producttwo} alt="productimg" />
                          </div>
                          <div className="productName">Sandwich</div>
                          <div className="productprice">$20</div>
                          <div>
                            <button className="button1">Puchase</button>
                          </div>
                        </div>
                      </Col>

                      <Col md={6} lg={4} xl={3} className="mb-3">
                        <div className="product_box">
                          <div className="product_img">
                            <img src={productthree} alt="productimg" />
                          </div>
                          <div className="productName">Coffee</div>
                          <div className="productprice">$20</div>
                          <div>
                            <button className="button1">Puchase</button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="wallet_greten_air">
                    <div className="wallet_amount text-start">
                      <span>Wallet Amount</span>
                      <p>500$</p>
                    </div>
                    <div>
                      <button className="button1 m-0">
                        Add Money in wallet
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="skip_btn">
    
        <Link to="/boardingflight" className="button1">
          Next
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default Shop;
