
import Index from "../../panel/admin/user_admin/Index";
import Login from "../../panel/auth/admin/Login.jsx";
import UserList from "../../panel/admin/user_admin/UserList.js";
export const routesForLogin = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
];

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Index />,
    layout: "/admin",
  },
  {
    path: "/UserList",
    name: "UserList",
    icon: "ni ni-bullet-list-67 text-yellow",
    component: <UserList />,
    layout: "/admin",
  },
];
export default routes;
