
import PropTypes from 'prop-types';
import React, {  useState,useEffect } from "react";
import { useLocation, Route, Routes, Navigate, useNavigate } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "../../panel/admin/inc/Navbar/AdminNavbar.js";
import AdminFooter from "../../panel/admin/inc/Footer/AdminFooter.js";
import Sidebar from "../../panel/admin/inc/Sidebar/Sidebar.js";

import routes from "../admin/routes.js";
import "../../assets/admin/plugins/nucleo/css/nucleo.css";
import "../../assets/admin/css/argon-dashboard-react.css";
import { getAllLocatData } from '../../components/Utility.jsx';
import { Loader } from '../../components/Loader.jsx';
// import "@fortawesome/fontawesome-free/css/all.min.css";
const Admin = (props) => {

  const navigate = useNavigate()

  useEffect(() => {
    if (!getAllLocatData('admin')) {
      navigate('/auth/login')
    }
  }, [])

  const mainContent = React.useRef(null);
  const location = useLocation();
  const currentPath = location.pathname;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [currentPath]);

  Admin.propTypes = {
    location: PropTypes.object.isRequired // Adjust the prop type according to its structure
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
   // mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props?.location?.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };


  return (
    <>
    {
      loading ? <>
        <Loader />
      </>
        :
    <>
    
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../../assets/admin/img/brand/GretenAir.png"),

          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props?.location?.pathname)}
        />


        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/admin/index" replace />} />
        </Routes>

        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
    }
    </>
  );
};

export default Admin;
