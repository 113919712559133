import React, { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";
import { Country } from "country-state-city";
import "./css/updateuser.css";
import {
  apiBaseURL,
  getAllLocatData,
  getCurrentDate,
  toastifyError,
  toastifySuccess,
} from "../../../components/Utility";
import axios from "axios";
import { axiosInstance } from "../../../api/interceptor";
import "react-phone-input-2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import BackBTN from '../../../components/BackBTN';
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const steps = ["Profile Details", "language", "Contact"];

const EditForm = ({ setPage, userData, userListFun }) => {
  const [loading, setLoading] = useState(false);
  const [findCountryCode, setFindCountryCode] = useState("au");
  const [countryName, setCountryName] = useState("Australia");

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ?
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const { getCode, getName } = require("country-list");
  const [languageArr, setLanguageArr] = useState([])
  const [langHide, setLangHide] = useState(false)

  const [value, setValue] = useState({
    email: "",
    language: "",
    language_level: "",

    dob: "",
    prefix: "",
    country: "",
    phone: "",
    last_name: "",
    first_name: "",
  });
  useEffect(() => {
    if (userData?._id) {
      setValue({
        ...value,
        user_id: userData?._id,
        email: userData?.email,
        language: '',
        language_level: '',
        dob: userData?.dob,
        prefix: userData?.prefix,
        country: userData?.country,
        phone: userData?.phone,
        last_name: userData?.last_name,
        first_name: userData?.first_name,
      });
      setLanguageArr(userData?.languages)
    }
  }, [userData]);
  const handleChange = (e) => {
    if (e.target.name === "country") {
      setCountryName(e.target.value);
    }
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const UpdateUserData = async (e) => {
    e.preventDefault();
    // setLoading(true);
    let params = languageArr
    if (value.language !== "" && value.language_level !== '') {
      const arr = { language: value.language, language_level: value.language_level }
      const langArr = [...languageArr, arr]
      params = { ...value, languages: langArr }
    } else {
      params = { ...value, languages: languageArr }
    }
    try {
      const headers = {
        Authorization: `Bearer ${getAllLocatData("user")?.token}`,
      };
      const res = await axios.post(`${apiBaseURL()}/user/updateProfile`, params, { headers });
      if (res.data.status === 200) {
        setLoading(false);
        toastifySuccess(res.data.message || "Profile updated successfully");
        setPage(false)
        userListFun()
      } else {
        setLoading(false);
        toastifyError(
          res.data.message || "Something went wrong while updating profile"
        );
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      toastifyError("Internal server error");
    }
  };

  const addMoreLanguage = () => {
    if (!langHide) {
      setLangHide(true)
      return false
    }
    if (value.language == "" && value.language_level == "") {
      toastifyError("Please Select Language and Level")
      return false
    }
    const newData = {
      'language': value.language,
      'language_level': value.language_level,
    };
    setLanguageArr(prevValues => [...prevValues, newData]);
    setValue({ ...value, 'language': '', 'language_level': '' })
  }

  const removeLanguage = (index) => {
    const newItems = languageArr.filter((item, i) => i !== index);
    setLanguageArr(newItems);
  }
  const languageHideFun = () => {
    setLangHide(!langHide)
    setValue({ ...value, 'language': '', 'language_level': '' })
  }
  return (
    <div className="manageprofile p-0">
      <div className="back_user">
        <button className="back_btn" onClick={() => setPage(false)}>
          <i className="fa-solid fa-arrow-left"></i>
        </button>
      </div>
      <div className="step_edit mt-3 mb-4">
        <Row className="justify-content-center">
          <Col md={8}>
            <div className="my_profile">
              <div className="global_geading">
                <p>User</p>
                <h2>Edit User</h2>
              </div>
            </div>
          </Col>
          <Col md={8} className="mt-6">
            <Box sx={{ width: "100%" }}>
              <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                {allStepsCompleted() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                      {activeStep == 0 ? (
                        <div className="first_step about_field">
                          <Row className="form">
                            <Col md={4} className="mb-5">
                              <div className="main_input prefix_input ">
                                <div className="global_arrow half_arrow">
                                  <select
                                    name="prefix"
                                    onChange={handleChange}
                                    value={value.prefix}
                                  >
                                    <option>Mr</option>
                                    <option>Ms</option>
                                    <option>Mrs</option>
                                    <option>Miss</option>
                                    <option>Mx</option>
                                    <option>Doctor</option>
                                    <option>Master</option>
                                    <option>Captain</option>
                                    <option>Dame</option>
                                    <option>Judge</option>
                                    <option>Lady</option>
                                    <option>Lord</option>
                                  </select>
                                  <div className="cus_arrow">
                                    <i className="fa-solid fa-angle-down"></i>
                                  </div>
                                </div>
                                <input
                                  className=""
                                  value={value.first_name}
                                  name="first_name"
                                  onChange={handleChange}
                                  type="text"
                                  placeholder="First name"
                                />
                              </div>
                            </Col>
                            <Col md={4} className="mb-5">

                              <input
                                value={value.last_name}
                                name="last_name"
                                onChange={handleChange}
                                type="text"
                                placeholder="Last name"
                              />
                            </Col>
                            <Col md={4} className="mb-5">
                              <div className="main_date">
                                <label className="label_txt">Date of Birth</label>
                                <input
                                  type="date"
                                  value={value.dob}
                                  name="dob"
                                  onChange={handleChange}
                                  placeholder="date"
                                  max={getCurrentDate()}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ) : activeStep == 1 ? (
                        <div className="sec_step about_field">

                          {
                            languageArr?.map((data, i) => (
                              <>
                                <Row className="justify-content-end">
                                  <Col md={6} className="mb-3">
                                    <div className="global_arrow">
                                      <select
                                        value={data.language}
                                        name="language"
                                        onChange={handleChange}
                                        className="w-100"
                                        disabled
                                      >
                                        <option value="">Select Language</option>
                                        <option>English</option>
                                        <option>Mandarin</option>
                                        <option>French</option>
                                        <option>Spanish</option>
                                        <option>Portuguese</option>
                                        <option>Hindi</option>
                                        <option>Japanese</option>
                                        <option>Vietnamese</option>
                                        <option>Korean</option>
                                        <option>Urdu</option>
                                        <option>Italian</option>
                                        <option>Turkic</option>
                                        <option>Sino-Tibetan</option>
                                        <option>Indo-European</option>
                                        <option>Russian</option>
                                        <option>Javanese</option>
                                        <option>Yue Chinese</option>
                                        <option>Egyptian Arabic</option>
                                        <option>Swahili </option>
                                        <option>Indonesian</option>
                                        <option>Polish</option>
                                      </select>
                                      <div className="cus_arrow">
                                        <i className="fa-solid fa-angle-down"></i>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6} className="mb-3">
                                    <div className="global_arrow">
                                      <select
                                        value={data.language_level}
                                        name="language_level"
                                        onChange={handleChange}
                                        className="w-100"
                                        disabled
                                      >
                                        <option value="">Select Language</option>
                                        <option>Native</option>
                                        <option>Fluent</option>
                                        <option>Competent</option>
                                        <option>Elementary</option>
                                        <option>Learning</option>
                                      </select>
                                      <div className="cus_arrow">
                                        <i className="fa-solid fa-angle-down"></i>
                                      </div>
                                    </div>
                                  </Col>
                                  {
                                    languageArr?.length > 1 &&
                                    <Col md={4} className="mb-2 text-end">
                                      <button className="text-danger bg-white m-0 border-0" onClick={() => removeLanguage(i)}> <i class="fa-regular fa-trash-can"></i></button>
                                    </Col>
                                  }
                                </Row>
                              </>
                            ))
                          }
                          <Row className="form">

                            {
                              langHide && <>
                                <Col md={6} className="mb-2">
                                  <div className="global_arrow">
                                    <select
                                      value={value.language}
                                      name="language"
                                      onChange={handleChange}
                                      className="w-100"
                                    >
                                      <option value="">Select Language</option>
                                      <option>English</option>
                                      <option>Mandarin</option>
                                      <option>French</option>
                                      <option>Spanish</option>
                                      <option>Portuguese</option>
                                      <option>Hindi</option>
                                      <option>Japanese</option>
                                      <option>Vietnamese</option>
                                      <option>Korean</option>
                                      <option>Urdu</option>
                                      <option>Italian</option>
                                      <option>Turkic</option>
                                      <option>Sino-Tibetan</option>
                                      <option>Indo-European</option>
                                      <option>Russian</option>
                                      <option>Javanese</option>
                                      <option>Yue Chinese</option>
                                      <option>Egyptian Arabic</option>
                                      <option>Swahili </option>
                                      <option>Indonesian</option>
                                      <option>Polish</option>
                                    </select>
                                    <div className="cus_arrow">
                                      <i className="fa-solid fa-angle-down"></i>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6} className="mb-2">
                                  <div className="global_arrow">
                                    <select
                                      value={value.language_level}
                                      name="language_level"
                                      onChange={handleChange}
                                      className="w-100"
                                    >
                                      <option value="">Select Language</option>
                                      <option>Native</option>
                                      <option>Fluent</option>
                                      <option>Competent</option>
                                      <option>Elementary</option>
                                      <option>Learning</option>
                                    </select>
                                    <div className="cus_arrow">
                                      <i className="fa-solid fa-angle-down"></i>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={12} className="mb-2 text-end">
                                  {
                                    (languageArr?.length > 0 && langHide) &&
                                    <button className=" dlt_btn_add text-danger bg-white m-0 border-0" onClick={() => languageHideFun()}><i class="fa-regular fa-trash-can"></i></button>
                                  }
                                </Col>
                              </>
                            }
                            <div className="mb-3 text-end">
                              <button type='button' className="add_btn border-0" onClick={() => addMoreLanguage()}>Add more</button>
                            </div>
                          </Row>
                        </div>
                      ) : (
                        <div className="third_step about_field">
                          <Row className="form">

                            <Col md={4} className="mb-5">
                              <input
                                type="text"
                                placeholder="Email "
                                value={value.email}
                                name="email"
                                onChange={handleChange}
                              />
                            </Col>
                            <Col md={4} className="mb-5">
                              <PhoneInput
                                country={findCountryCode}
                                enableSearch={true}
                                value={value.phone} // Current phone value
                                onChange={(phone, country) => {
                                  // Extract country code from the selected country
                                  setFindCountryCode(country ? country.countryCode : "");
                                  setCountryName(getName(country ? country.countryCode : ""));
                                  // Update phone value in state
                                  setValue({ ...value, phone });
                                }} // onChange handler
                                inputProps={{
                                  name: "phone",
                                  className: "pl-5",
                                  // className: checkError(error.phone) ? 'error_outline' : '',
                                  placeholder: "Mobile Number",
                                }} // Input properties
                              />
                              {/* <input type="text" placeholder="Mobile Number"
             value={value.phone} name="phone" onChange={handleChange} /> */}
                            </Col>
                            <Col md={4} className="mb-5">
                              <div className="global_arrow">
                                <select
                                  value={countryName}
                                  name="country"
                                  onChange={handleChange}
                                  className="w-100"
                                >
                                  <option value="">Select Country</option>
                                  {Country?.getAllCountries()?.map((item, i) => (
                                    <option value={item.name} key={i}>
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                                <div className="cus_arrow">
                                  <i className="fa-solid fa-angle-down"></i>
                                </div>
                              </div>
                            </Col>

                          </Row>
                        </div>
                      )}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        className="button1"
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button className="button1" onClick={handleNext} sx={{ mr: 1 }}>
                        Next
                      </Button>
                      {activeStep !== steps.length &&
                        (completed[activeStep] ? (
                          <Typography
                            variant="caption"
                            sx={{ display: "inline-block" }}
                          >
                            Step {activeStep + 1} already completed
                          </Typography>
                        ) : (
                          <>
                            {loading ? (
                              <>
                                <Button className="button1" sx={{ mr: 1 }}>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>{" "}
                                  Loading...
                                </Button>
                              </>
                            ) : (
                              <button className="button1 border-0" onClick={UpdateUserData}>
                                Save
                              </button>
                            )}
                          </>
                        ))}
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Box>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EditForm;
