import React from "react";
import "../../../panel/front/booking/booking.css";
import { Col, Container, Row } from "react-bootstrap";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import succesfully from "../../../assets/images/success-icon.gif";
import aerowindow from "../../../assets/images/aeroplnewindow.png";
import { Link } from "react-router-dom";
const steps = ["Personal Info", "Select Seat", "Make payment"];
const Booking = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  return (
    <div className="booking_steps">
      <section className="brread_img_top">
        <Container>
          <div className="quote">
            Fly virtually to anywhere 100% on time with zero emissions and no
            cancellations
          </div>
        </Container>
      </section>

      <section className="htmlForm_booking">
        <Container>
          <div className="box_heading text-start">Passenger Details</div>
          <div className="htmlForm_booking_inner">
            <Row className="justify-content-center">
              <Col md={10}>
                <div className="global_bg_box">
                <Box sx={{ width: "100%" }}>
                  <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                      <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                    {allStepsCompleted() ? (
                      <React.Fragment>
                        <Typography
                          className="text-center"
                          sx={{ mt: 2, mb: 1 }}
                        >
                          <div className="succes_box">
                            {" "}
                            <img src={succesfully} alt="succes img" />
                            <h2 className="mt-3">
                              Your account has been created successfully. A
                              confirmation email has been sent to your email
                              address.
                            </h2>
                          </div>
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            pt: 2,
                          }}
                        >
                          <Box sx={{ flex: "1 1 auto" }} />
                        </Box>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                          {activeStep == 0 ? (
                            <div className="htmlForm">
                              <Row>
                                <Col md={6} lg={4} className="mb-4">
                                <div className="main_input">
                           <select>
                              <option>Mr</option>
                              <option>Ms</option>
                              <option>Mrs</option>
                              <option>Miss</option>
                              <option>Mx</option>
                              <option>Doctor</option>
                              <option>Master</option>
                              <option>Captain</option>
                              <option>Dame</option>
                              <option>Judge</option>
                              <option>Lady</option>
                              <option>Lord</option>
                             </select>
                                    <input className="multi_input" type="text" placeholder="Username" />
                           </div>
                                </Col>
                                <Col md={6} lg={4} className="mb-4">
                                  <input type="text" placeholder="Password" />
                                </Col>

                                <Col md={6} lg={4} className="mb-4">
                                <div className="main_date">
                                   <label className="label_txt">Date of Birth</label>
                                    <input type="date" placeholder="date" />
                                   </div>
                                </Col>

                                <Col md={6} lg={4} className="mb-4">
                                  <input type="text" placeholder="Email" />
                                </Col>

                                <Col md={6} lg={4} className="mb-4">
                                  <input
                                    type="text"
                                    placeholder="Mobile Number"
                                  />
                                </Col>
                              </Row>
                            </div>
                          ) : activeStep == 1 ? (
                            <div className="select_seat">
                              <Row className="justify-content-center">
                                <Col md={3}>
                                 <div className="about_seat">
                                  <ul className="p-0 m-0">
                                    <li className="d-flex text-black">
                                      <div className="about_color"></div>
                                      <div className="price">Women</div>
                                    </li>
                                    <li className="d-flex text-black">
                                      <div className="about_color dark_blue"></div>
                                      <div className="price">Boys</div>
                                    </li>
                                    <li className="d-flex text-black">
                                      <div className="about_color blue"></div>
                                      <div className="price">$100 -400</div>
                                    </li>
                                  </ul>
                                 </div>
                                </Col>
                                <Col md={9}>
                                  <div className="outer_plane">
                                    <div className="plane">
                                      <div className="cockpit">
                                        <h1>
                                          <img
                                            src={aerowindow}
                                            alt="plnewindow"
                                          />
                                        </h1>
                                      </div>
                                      <div className="exit exit--front fuselage"></div>
                                      <ol className="cabin fuselage">
                                        <li className="row row--1">
                                          <ol className="seats" type="A">
                                            <li className="seat">
                                              <input type="checkbox" id="1A" />
                                              <label htmlFor="1A">1A</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="1B" />
                                              <label htmlFor="1B">1B</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="1C" />
                                              <label htmlFor="1C">1C</label>
                                            </li>
                                            <li className="seat">
                                              <input
                                                type="checkbox"
                                                disabled
                                                id="1D"
                                              />
                                              <label htmlFor="1D">Occupied</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="1E" />
                                              <label htmlFor="1E">1E</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="1F" />
                                              <label htmlFor="1F">1F</label>
                                            </li>
                                          </ol>
                                        </li>
                                        <li className="row row--2">
                                          <ol className="seats" type="A">
                                            <li className="seat">
                                              <input type="checkbox" id="2A" />
                                              <label htmlFor="2A">2A</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="2B" />
                                              <label htmlFor="2B">2B</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="2C" />
                                              <label htmlFor="2C">2C</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="2D" />
                                              <label htmlFor="2D">2D</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="2E" />
                                              <label htmlFor="2E">2E</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="2F" />
                                              <label htmlFor="2F">2F</label>
                                            </li>
                                          </ol>
                                        </li>
                                        <li className="row row--3">
                                          <ol className="seats" type="A">
                                            <li className="seat">
                                              <input type="checkbox" id="3A" />
                                              <label htmlFor="3A">3A</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="3B" />
                                              <label htmlFor="3B">3B</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="3C" />
                                              <label htmlFor="3C">3C</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="3D" />
                                              <label htmlFor="3D">3D</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="3E" />
                                              <label htmlFor="3E">3E</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="3F" />
                                              <label htmlFor="3F">3F</label>
                                            </li>
                                          </ol>
                                        </li>
                                        <li className="row row--4">
                                          <ol className="seats" type="A">
                                            <li className="seat">
                                              <input type="checkbox" id="4A" />
                                              <label htmlFor="4A">4A</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="4B" />
                                              <label htmlFor="4B">4B</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="4C" />
                                              <label htmlFor="4C">4C</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="4D" />
                                              <label htmlFor="4D">4D</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="4E" />
                                              <label htmlFor="4E">4E</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="4F" />
                                              <label htmlFor="4F">4F</label>
                                            </li>
                                          </ol>
                                        </li>
                                        <li className="row row--5">
                                          <ol className="seats" type="A">
                                            <li className="seat">
                                              <input type="checkbox" id="5A" />
                                              <label htmlFor="5A">5A</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="5B" />
                                              <label htmlFor="5B">5B</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="5C" />
                                              <label htmlFor="5C">5C</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="5D" />
                                              <label htmlFor="5D">5D</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="5E" />
                                              <label htmlFor="5E">5E</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="5F" />
                                              <label htmlFor="5F">5F</label>
                                            </li>
                                          </ol>
                                        </li>
                                        <li className="row row--6">
                                          <ol className="seats" type="A">
                                            <li className="seat">
                                              <input type="checkbox" id="6A" />
                                              <label htmlFor="6A">6A</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="6B" />
                                              <label htmlFor="6B">6B</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="6C" />
                                              <label htmlFor="6C">6C</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="6D" />
                                              <label htmlFor="6D">6D</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="6E" />
                                              <label htmlFor="6E">6E</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="6F" />
                                              <label htmlFor="6F">6F</label>
                                            </li>
                                          </ol>
                                        </li>
                                        <li className="row row--7">
                                          <ol className="seats" type="A">
                                            <li className="seat">
                                              <input type="checkbox" id="7A" />
                                              <label htmlFor="7A">7A</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="7B" />
                                              <label htmlFor="7B">7B</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="7C" />
                                              <label htmlFor="7C">7C</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="7D" />
                                              <label htmlFor="7D">7D</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="7E" />
                                              <label htmlFor="7E">7E</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="7F" />
                                              <label htmlFor="7F">7F</label>
                                            </li>
                                          </ol>
                                        </li>
                                        <li className="row row--8">
                                          <ol className="seats" type="A">
                                            <li className="seat">
                                              <input type="checkbox" id="8A" />
                                              <label htmlFor="8A">8A</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="8B" />
                                              <label htmlFor="8B">8B</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="8C" />
                                              <label htmlFor="8C">8C</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="8D" />
                                              <label htmlFor="8D">8D</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="8E" />
                                              <label htmlFor="8E">8E</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="8F" />
                                              <label htmlFor="8F">8F</label>
                                            </li>
                                          </ol>
                                        </li>
                                        <li className="row row--9">
                                          <ol className="seats" type="A">
                                            <li className="seat">
                                              <input type="checkbox" id="9A" />
                                              <label htmlFor="9A">9A</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="9B" />
                                              <label htmlFor="9B">9B</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="9C" />
                                              <label htmlFor="9C">9C</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="9D" />
                                              <label htmlFor="9D">9D</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="9E" />
                                              <label htmlFor="9E">9E</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="9F" />
                                              <label htmlFor="9F">9F</label>
                                            </li>
                                          </ol>
                                        </li>
                                        <li className="row row--10">
                                          <ol className="seats" type="A">
                                            <li className="seat">
                                              <input type="checkbox" id="10A" />
                                              <label htmlFor="10A">10A</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="10B" />
                                              <label htmlFor="10B">10B</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="10C" />
                                              <label htmlFor="10C">10C</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="10D" />
                                              <label htmlFor="10D">10D</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="10E" />
                                              <label htmlFor="10E">10E</label>
                                            </li>
                                            <li className="seat">
                                              <input type="checkbox" id="10F" />
                                              <label htmlFor="10F">10F</label>
                                            </li>
                                          </ol>
                                        </li>
                                      </ol>
                                      <div className="exit exit--back fuselage"></div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            <div className="booking_deatils">
                              <Row className="justify-content-center">
                                <Col md={6} lg={5}>
                                  <div className="bookings_box">
                                    <h3>Booking Amount</h3>
                                    <ul className="p-0 mt-3">
                                      <li>
                                        <div className="about_price">
                                          Adult Price × 1
                                        </div>
                                        <div className="about_price_amount">
                                          40,000{" "}
                                        </div>
                                      </li>

                                      <li>
                                        <div className="about_price">
                                          Discount
                                        </div>
                                        <div className="about_price_amount">
                                       -10%
                                        </div>
                                      </li>

                                      <li>
                                        <div className="about_price">
                                        Tax
                                        </div>
                                        <div className="about_price_amount">
                                       -5%
                                        </div>
                                      </li>

                                      <li className="total_amount_bokkings">
                                        <div className="about_price">
                                        Total Amonut
                                        </div>
                                        <div className="about_price_amount">
                                       40,000
                                        </div>
                                      </li>
                                      

                                      <li className="total_amount_bokkings text-center justify-content-center border-0">
                                        <Link className="button1" to="/takeyourflight">Take Your flight</Link>
                                      </li>
                                    </ul>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Typography>
                        <Box
                          className="justify-content-end"
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "end",
                            alignItems: "flex-end",
                            pt: 2,
                          }}
                        >
                          <Button
                            className="button1"
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                          >
                            Back
                          </Button>

                          <Button    className="button1" onClick={handleNext} sx={{ mr: 1 }}>
                              Next
                            </Button>

                          {activeStep !== steps.length &&
                            (completed[activeStep] ? (
                              <Typography
                                variant="caption"
                                sx={{ display: "inline-block" }}
                              >
                                Step {activeStep + 1} already completed
                              </Typography>
                            ) : (
                              <Button
                                className="button1 d-none"
                                onClick={handleComplete}
                              >
                                {completedSteps() === totalSteps() - 1
                                  ? "Finish"
                                  : "Next"}
                              </Button>
                            ))}
                        </Box>
                      </React.Fragment>
                    )}
                  </div>
                </Box>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Booking;
