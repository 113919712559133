import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import config from '../../../config';
import '../../auth/user/css/login.css';
import '../../auth/user/css/register.css';
import Footer from '../../front/include/Footer';
import logo from '../../../assets/images/GretenAir.png';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import succesfully from '../../../assets/images/success-icon.gif';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import BackBTN from '../../../components/BackBTN';
import { Country } from 'country-state-city';
import isoLangs from 'iso-639-1';
import { axiosInstance } from '../../../api/interceptor';
import axios from 'axios';
import { getCurrentDate, encryptLocalStorageData, toastifyError, toastifySuccess } from '../../../components/Utility';
import validator from 'validator';
import "react-phone-input-2";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';


const steps = ['Name', 'Personal Info', 'Create Password'];
const Regsiter = () => {
  const navigate = useNavigate()
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [isCompleted, setIsCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [messageSent, setMessageSent] = useState(false);

  const [findCountryCode, setFindCountryCode] = useState('au')
  const [countryName, setCountryName] = useState('Australia')


  const { getCode, getName } = require('country-list');
  const [languageArr, setLanguageArr] = useState([])


  const validate = (value) => {

    if (validator.isStrongPassword(value, {
      minLength: 8, minLowercase: 1,
      minUppercase: 1, minNumbers: 1, minSymbols: 1
    })) {
      setErrorMessage('Is Strong Password')
    } else {
      setErrorMessage('Is Not Strong Password')
    }
  }
  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return isCompleted;
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate('/login');
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const [value, setValue] = useState({
    first_name: '',
    last_name: '',
    prefix: 'Mr',
    dob: '',
    email: '',
    phone: '',
    country: '',
    language: '',
    language_level: "",
    password: '',
    confirm_password: '',
    agree_check: false
  });

  const [error, setError] = useState({
    first_name: '',
    last_name: '',
    dob: '',
    email: '',
    phone: '',
    country: '',
    language_level: '',
    level: "",
    password: '',
    confirm_password: '',
    agree_check: false
  });

  const handleChange = (e) => {
    setError((prevValues) => {
      return { ...prevValues, [e.target.name]: '' };
    });



    if (e.target.name === 'country') {
      setCountryName(e.target.value);
      setValue({
        ...value,
        country: e.target.value
      });

      if (e.target.value.trim() === '') {
        setError((prevValues) => {
          return { ...prevValues, ['country']: 'This field is required!!' };
        });
      } else {
        setError((prevValues) => {
          return { ...prevValues, ['country']: true };
        });
      }
    }
    else {
      setValue({
        ...value,
        [e.target.name]: e.target.value
      });
    }
  };
  const [addUpdateApiCallCount, setAddUpdateApiCallCount] = useState(0);
  const [langHide, setLangHide] = useState(true)

  const handleValidate = () => {
    const { first_name, last_name, language, language_level, dob, phone, email, country, password, confirm_password, agree_check } = value;
    setAddUpdateApiCallCount(addUpdateApiCallCount + 1);
    if (activeStep === 0) {
      if (first_name.trim() == '') {
        setError((prevValues) => {
          return { ...prevValues, ['first_name']: 'This field is required!!' };
        });
      } else {
        setError((prevValues) => {
          return { ...prevValues, ['first_name']: true };
        });
      }

      if (last_name.trim() == '') {
        setError((prevValues) => {
          return { ...prevValues, ['last_name']: 'This field is required!!' };
        });
      } else {
        setError((prevValues) => {
          return { ...prevValues, ['last_name']: true };
        });
      }

      if (langHide) {
        if (language.trim() == '') {
          setError((prevValues) => {
            return { ...prevValues, ['language']: 'This field is required!!' };
          });
        } else {
          setError((prevValues) => {
            return { ...prevValues, ['language']: true };
          });
        }
        if (language_level.trim() == '') {
          setError((prevValues) => {
            return { ...prevValues, ['language_level']: 'This field is required!!' };
          });
        } else {
          setError((prevValues) => {
            return { ...prevValues, ['language_level']: true };
          });
        }
      } else {
        setError((prevValues) => {
          return { ...prevValues, ['language']: true, ['language_level']: true };
        });
      }

    }

    if (activeStep === 1) {
      if (email.trim() == '') {
        setError((prevValues) => {
          return { ...prevValues, ['email']: 'This field is required!!' };
        });
      } else {
        setError((prevValues) => {
          return { ...prevValues, ['email']: true };
        });
      }

      if (phone.length < 8) {
        setError((prevValues) => {
          return { ...prevValues, ['phone']: 'This field is required!!' };
        });
      } else {
        setError((prevValues) => {
          return { ...prevValues, ['phone']: true };
        });
      }
      if (dob.trim() == '') {
        setError((prevValues) => {
          return { ...prevValues, ['dob']: 'This field is required!!' };
        });
      } else {
        setError((prevValues) => {
          return { ...prevValues, ['dob']: true };
        });
      }
      // if (country.trim() == '') {
      //   setError((prevValues) => {
      //     return { ...prevValues, ['country']: 'This field is required!!' };
      //   });
      // } else {
      //   setError((prevValues) => {
      //     return { ...prevValues, ['country']: true };
      //   });
      // }
    }

    if (activeStep === 2) {
      //Password is required
      if (password.trim() == '') {
        setError((prevValues) => {
          return { ...prevValues, ['password']: 'Password is required!!' };
        });
      }
      //Password must be strong
      else if (!validator.isStrongPassword(password, {
        minLength: 8, minLowercase: 1,
        minUppercase: 1, minNumbers: 1, minSymbols: 1
      })) {
        setError((prevValues) => {
          return { ...prevValues, ['password']: 'Password must be strong' };
        });
      }
      else {
        setError((prevValues) => {
          return { ...prevValues, ['password']: true };
        });
      }
      //Confirm Password is required
      if (confirm_password.trim() == '') {
        setError((prevValues) => {
          return { ...prevValues, ['confirm_password']: 'Confirm Password is required !!' };
        });
        //Passwords do not match
      } else if (password !== confirm_password) {
        setError((prevValues) => {
          return { ...prevValues, ['confirm_password']: 'Passwords do not match' };
        });
      } else {
        setError((prevValues) => {
          return { ...prevValues, ['confirm_password']: true };
        });
      }

      if (agree_check === false) {
        setError((prevValues) => ({
          ...prevValues,
          agree_check: 'This field is required!!'
        }));
      } else {
        setError((prevValues) => ({
          ...prevValues,
          agree_check: true
        }));
      }
    }

  };

  const { first_name, last_name, language, language_level, dob, phone, email, country, password, confirm_password, agree_check } = error;
  useEffect(() => {
    if (activeStep === 0) {
      if (first_name === true && last_name === true && language === true && language_level === true) {
        handleNext();
      }
    }

    if (activeStep === 1) {
      if (phone === true && email === true && dob === true) {
        handleNext();
      }
    }

    if (activeStep === 2) {
      if (password === true && confirm_password === true && agree_check === true) {
        handleRegister();
      }
    }
  }, [addUpdateApiCallCount]);

  const handleRegister = async () => {
    setLoading(true)
    let params = languageArr
    if (value.language !== "" && value.language_level !== '') {
      const arr = { language: value.language, language_level: value.language_level }
      const langArr = [...languageArr, arr]
      params = { ...value, languages: langArr }
    } else {
      params = { ...value, languages: languageArr }
    }
    try {
      const res = await axios.post(`${config.url}/user/registerUser`, params)
      if (res.status) {
        const dataParam = {
          token: res?.data.token,
          login_id: res?.data?.newUser?._id,
          first_name: res?.data?.newUser?.first_name,
          last_name: res?.data?.newUser?.last_name,
          phone: res?.data?.newUser?.phone,
          email: res?.data?.newUser?.email,
          prefix: res?.data?.newUser?.prefix,
          role: "user",
        };
        encryptLocalStorageData('gretenair-web-secret', dataParam, 'DoNotTryToAccess');

        setTimeout(() => {
          setIsCompleted(true)
          toastifySuccess("Register successfully!!")
          setLoading(false)
          navigate('/manageprofile');
        }, 2000);
      } else {
        setLoading(false)
        toastifyError("Something wen't wrong !!")
      }
    } catch (error) {
      console.log(error)
      toastifyError(error.response.data.message)
      setLoading(false)
    }
  }

  const checkError = (field) => {
    if (field == "ok") {
      return true
    } else {
      return false
    }
  }

  const addMoreLanguage = () => {
    if (!langHide) {
      setLangHide(true)
      return false
    }
    if (value.language == "" && value.language_level == "") {
      toastifyError("Please Select Language and Level")
      return false
    }
    const newData = {
      'language': value.language,
      'language_level': value.language_level,
    };
    setLanguageArr(prevValues => [...prevValues, newData]);
    setValue({ ...value, 'language': '', 'language_level': '' })
  }

  const removeLanguage = (index) => {
    const newItems = languageArr.filter((item, i) => i !== index);
    setLanguageArr(newItems);
  }

  const languageHideFun = () => {
    setLangHide(!langHide)
    // setValue({ ...value, 'language': '', 'language_level': '' })
  }


  return (
    <div className="register_user">
      <div className="log_in_page">
        <Container fluid>
          <Row className="main align-items-center justify-content-center">
            <Col md={6}>
              <div className="main_outer_global_box slideDown">
                <div className="box_heading"> {activeStep === 2 ? 'Success' : 'CREATE AN ACCOUNT'} </div>
                <BackBTN />
                <div className="global_bg_box">
                  <Box sx={{ width: '100%' }}>
                    <div>
                      {allStepsCompleted() ? (
                        <React.Fragment>
                          <Typography className="text-center" sx={{ mt: 2, mb: 1 }}>
                            <div className="succes_box">
                              {' '}
                              <img src={succesfully} alt="succes img" />
                              <h2 className="mt-3">
                                Your account has been created successfully. A confirmation email has been sent to your email address.
                              </h2>
                            </div>
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              pt: 2
                            }}
                          >
                            <Box sx={{ flex: '1 1 auto' }} />
                          </Box>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Stepper nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => (
                              <Step key={label} completed={completed[index]}>
                                <StepButton color="inherit" >
                                  {label}
                                </StepButton>
                              </Step>
                            ))}
                          </Stepper>
                          <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                            {activeStep == 0 ? (
                              <div className="form">
                                <Row className="align-items-start justify-content-end">
                                  <Col lg={6} className="mb-4">
                                    <div className="main_input ">
                                      <div className='global_arrow half_arrow'>
                                        <select name="prefix" onChange={handleChange}
                                          value={value.prefix}
                                        >
                                          <option>Mr</option>
                                          <option>Ms</option>
                                          <option>Mrs</option>
                                          <option>Miss</option>
                                          <option>Mx</option>
                                          <option>Doctor</option>
                                          <option>Master</option>
                                          <option>Captain</option>
                                          <option>Dame</option>
                                          <option>Judge</option>
                                          <option>Lady</option>
                                          <option>Lord</option>
                                        </select>
                                        <div className='cus_arrow'><i class="fa-solid fa-angle-down"></i></div>
                                      </div>

                                      <input
                                        className={"multi_input " + (checkError(error.first_name) && "error_outline")}
                                        value={value.first_name}
                                        name="first_name"
                                        onChange={handleChange}
                                        type="text"

                                        placeholder="First name"
                                      />
                                    </div>
                                    <span className="errorMsg mt-2 d-block">{error.first_name}</span>
                                  </Col>

                                  <Col lg={6} className="mb-4">
                                    <div className="main_input">
                                      <input
                                        value={value.last_name}
                                        name="last_name"
                                        onChange={handleChange}
                                        type="text"
                                        className={checkError(error.last_name) && 'error_outline'}
                                        placeholder="Last name"
                                      />
                                    </div>
                                    <span className="errorMsg mt-2 d-block">{error.last_name}</span>
                                  </Col>


                                  <Col lg={12} className='add_input'>
                                    {
                                      languageArr?.map((data, i) => (
                                        <>
                                          <Row className='justify-content-end'>
                                            <Col md={6} className='mb-2 mt-2 '>
                                              <div className="global_arrow">
                                                <select
                                                  value={data.language}
                                                  name="language"
                                                  onChange={handleChange}
                                                  className="w-100"
                                                  disabled
                                                >
                                                  <option value="">Select Language</option>
                                                  <option>English</option>
                                                  <option>Mandarin</option>
                                                  <option>French</option>
                                                  <option>Spanish</option>
                                                  <option>Portuguese</option>
                                                  <option>Hindi</option>
                                                  <option>Japanese</option>
                                                  <option>Vietnamese</option>
                                                  <option>Korean</option>
                                                  <option>Urdu</option>
                                                  <option>Italian</option>
                                                  <option>Turkic</option>
                                                  <option>Sino-Tibetan</option>
                                                  <option>Indo-European</option>
                                                  <option>Russian</option>
                                                  <option>Javanese</option>
                                                  <option>Yue Chinese</option>
                                                  <option>Egyptian Arabic</option>
                                                  <option>Swahili </option>
                                                  <option>Indonesian</option>
                                                  <option>Polish</option>
                                                </select>
                                                <div className="cus_arrow">
                                                  <i className="fa-solid fa-angle-down"></i>
                                                </div>
                                              </div>
                                            </Col>
                                            <Col md={6} className='mb-2 mt-2'>
                                              <div className="global_arrow">
                                                <select
                                                  value={data.language_level}
                                                  name="language_level"
                                                  onChange={handleChange}
                                                  className="w-100"
                                                  disabled
                                                >
                                                  <option value="">Select Language</option>
                                                  <option>Native</option>
                                                  <option>Fluent</option>
                                                  <option>Competent</option>
                                                  <option>Elementary</option>
                                                  <option>Learning</option>
                                                </select>
                                                <div className="cus_arrow">
                                                  <i className="fa-solid fa-angle-down"></i>
                                                </div>
                                              </div>
                                            </Col>
                                            {
                                              languageArr?.length > 1 &&
                                              <Col md={4} className='text-end'>
                                                <button className=" dlt_btn_add text-danger bg-white m-0 border-0" onClick={() => removeLanguage(i)}><i class="fa-regular fa-trash-can"></i></button>
                                              </Col>
                                            }
                                          </Row>
                                        </>
                                      ))
                                    }

                                  </Col>

                                  {
                                    langHide &&
                                    <>
                                      <Col lg={6} className="mb-2">
                                        <div className='global_arrow'>
                                          <select value={value.language} name="language" onChange={handleChange} className={"w-100 " + (checkError(error.language) && "error_outline")}>
                                            <option value="">Select Language </option>
                                            <option>English</option>
                                            <option>Mandarin</option>
                                            <option>French</option>
                                            <option>Spanish</option>
                                            <option>Portuguese</option>
                                            <option>Hindi</option>
                                            <option>Japanese</option>
                                            <option>Vietnamese</option>
                                            <option>Korean</option>
                                            <option>Urdu</option>
                                            <option>Italian</option>
                                            <option>Turkic</option>
                                            <option>Sino-Tibetan</option>
                                            <option>Indo-European</option>
                                            <option>Russian</option>
                                            <option>Javanese</option>
                                            <option>Yue Chinese</option>
                                            <option>Egyptian Arabic</option>
                                            <option>Swahili </option>
                                            <option>Indonesian</option>
                                            <option>Polish</option>

                                          </select>
                                          <div className='cus_arrow'><i class="fa-solid fa-angle-down"></i></div>
                                        </div>
                                        <span className="errorMsg d-block mt-2">{error.language}</span>
                                      </Col>
                                      <Col lg={6} className="mb-2">
                                        <div className='global_arrow'>
                                          <select value={value.language_level} name="language_level" onChange={handleChange} className={"w-100 " + (checkError(error.language_level) && "error_outline")}>
                                            <option value="">Language  Level</option>
                                            <option>Native</option>
                                            <option>Fluent</option>
                                            <option>Competent</option>
                                            <option>Elementary</option>
                                            <option>Learning</option>
                                          </select>
                                          <div className='cus_arrow'><i class="fa-solid fa-angle-down"></i></div>
                                        </div>
                                        <span className="errorMsg d-block mt-2">{error.language_level}</span>
                                      </Col>
                                      <Col md={4} className='text-end'>
                                        {
                                          (languageArr?.length > 0 && langHide) &&
                                          <button className=" dlt_btn_add text-danger bg-white m-0 border-0" onClick={() => languageHideFun()}><i class="fa-regular fa-trash-can"></i></button>
                                        }
                                      </Col>

                                    </>
                                  }

                                  <div className="mb-0 text-end">
                                    <button type='button' className="add_btn border-0 mt-2" onClick={() => addMoreLanguage()}><i class="fa-solid fa-plus me-2"></i> Add More Language </button>
                                  </div>


                                </Row>
                              </div>
                            ) : activeStep == 1 ? (
                              <div className="form">
                                <Row>
                                  <Col lg={6} className="mb-4">
                                    <input type="text" placeholder="Email " value={value.email} name="email" onChange={handleChange} className={checkError(error.email) && 'error_outline'} />
                                    <span className="errorMsg d-block mt-2">{error.email}</span>
                                  </Col>
                                  <Col lg={6} className="mb-4">
                                    {/* <input
                                      type="text"
                                      className={checkError(error.phone) && 'error_outline'}
                                      placeholder="Mobile Number"
                                      value={value.phone}
                                      name="phone"
                                      onChange={handleChange}
                                    /> */}
                                    <PhoneInput
                                      country={findCountryCode}  // Default country code

                                      enableSearch={true}
                                      value={value.phone} // Current phone value
                                      // onChange={(phone) => setValue({ ...value, phone })} // onChange handler
                                      onChange={(phone, country) => {
                                        // Extract country code from the selected country
                                        setFindCountryCode(country ? country.countryCode : '');
                                        setCountryName(getName(country ? country.countryCode : ''));
                                        // Update phone value in state
                                        setValue({ ...value, 'phone': phone });
                                      }}


                                      inputProps={{
                                        name: 'phone',

                                        className: checkError(error.phone) ? 'error_outline' : 'pl-5',
                                        placeholder: 'Mobile Number',
                                      }} // Input properties
                                    />

                                    <span className="errorMsg d-block mt-2">{error.phone}</span>
                                  </Col>
                                  <Col lg={6} className="mb-4">
                                    <div className="main_date">
                                      <label className="label_txt">Date of Birth</label>
                                      <input type="date" value={value.dob} name="dob" onChange={handleChange}
                                        max={getCurrentDate()}
                                        placeholder="date" className={checkError(error.dob) && 'error_outline'} />
                                    </div>
                                    <span className="errorMsg d-block mt-2">{error.dob}</span>
                                  </Col>
                                  <Col lg={6} className="mb-4">
                                    <div className='global_arrow'>
                                      <select value={countryName} name="country" onChange={handleChange} className={"w-100 " + (checkError(error.country) && "error_outline")}>
                                        <option value="">Select Country</option>
                                        {Country?.getAllCountries()?.map((item, i) => (
                                          <option value={item.name} key={i}>
                                            {item.name}
                                          </option>
                                        ))}
                                      </select>

                                      <div className='cus_arrow'><i class="fa-solid fa-angle-down"></i></div>
                                    </div>
                                    {/* <input type="text" placeholder="Country" value={value.country} name="country" onChange={handleChange} /> */}
                                    <span className="errorMsg d-block mt-2">{error.country}</span>
                                  </Col>
                                </Row>
                              </div>
                            ) : (
                              <div className="form">
                                <Row>
                                  <Col lg={6} className="mb-4">
                                    <input
                                      type="password"
                                      placeholder="Password "
                                      className={checkError(error.password) && 'error_outline'}
                                      value={value.password}
                                      name="password"
                                      onChange={handleChange}
                                    />
                                    <span className="errorMsg d-block mt-2">{error.password}</span>
                                  </Col>
                                  <Col lg={6} className="mb-4">
                                    <input
                                      type="password"
                                      placeholder="Confirm Password"
                                      className={checkError(error.confirm_password) && 'error_outline'}
                                      value={value.confirm_password}
                                      name="confirm_password"
                                      onChange={handleChange}

                                    />
                                    <span className="errorMsg d-block mt-2">{error.confirm_password}</span>
                                  </Col>
                                  <p >Password must be at least 8 characters long with 1 lowercase, 1 uppercase, 1 number, and 1 symbol.</p>
                                  <Col lg={12} className="mb-4">
                                    <div className="check">
                                      <label>
                                        <Form.Check
                                          checked={value.agree_check}
                                          name="agree_check"
                                          //onChange={handleCheckboxChange}
                                          onChange={(e) => setValue({ ...value, agree_check: e.target.checked })}
                                          className={checkError(error.agree_check) && 'error_outline'}

                                        />

                                        By submitting this form, I agree to GretenAir Terms and Condition and Privacy Policy
                                      </label>
                                    </div>
                                    <span className="errorMsg d-block mt-2">{error.agree_check}</span>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </Typography>
                          <Box
                            className="justify-content-end"
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'end',
                              alignItems: 'flex-end',
                              pt: 2
                            }}
                          >
                            <Button className="button1" color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
                              Back
                            </Button>
                            {
                              loading ? <>
                                <Button className="button1" sx={{ mr: 1 }} >
                                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                </Button>
                              </>
                                :
                                <Button className="button1" onClick={handleValidate} sx={{ mr: 1 }}>
                                  {activeStep === totalSteps() - 1 ? 'Finish' : 'Next'}
                                </Button>
                            }
                          </Box>
                        </React.Fragment>
                      )}
                    </div>
                  </Box>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Regsiter;
