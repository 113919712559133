import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import "../checkin/boarding.css";
import Footer from "../../front/include/Footer";
import chaimg from '../../../assets/images/chat.png'
import { Link } from 'react-router-dom';
import BackBTN from '../../../components/BackBTN';
const Optionchoose = () => {
  return (
    <div className="boarding">
    <div className="log_in_page choose_option">
      <Container fluid>
        <Row className="main align-items-center justify-content-center">
        <Col md={6} lg={4}>
            <div className="main_outer_global_box">
              <div className="box_heading">Bording on flight please wait...</div>
              <BackBTN/>
              <div className="global_bg_box text-center">
              
<div className='opt_box'>
   <div className='text-center'>
   <Link to="/chat" className='button1 m-auto'><i class="fa-solid fa-comments"></i></Link>
   <p>Chat With Nearby People</p>
   </div>
   <div className='text-center'>
   <Link to="" className='button1 m-auto'><i class="fa-solid fa-vr-cardboard"></i></Link>
   <p>360 Aeroplane  Tour</p>
   </div>
   
</div>
              </div>
            </div>
          </Col>

        
        </Row>
      </Container>
    </div>
    <Footer />
  </div>
  )
}

export default Optionchoose
