
import { Col, Container, Row } from "react-bootstrap";
import "../../front/boardflight/boardflight.css";
import Footer from "../../front/include/Footer";

import { Link } from "react-router-dom";

import aeroplan from "../../../assets/images/aeroplane.gif";
import BackBTN from "../../../components/BackBTN";

const Boardflight = () => {
  return (
    <div className="login_user">
      <div className="log_in_page boarding">
        <Container fluid>
          <Row className="main align-items-center justify-content-center">
            {/* <Col md={4} className="mb-5">
              <div className="main_outer_global_box">
                <div className="box_heading">Security Check</div>
                <div className="global_bg_box text-center">
                  <div className="securtiy_checkbox text-center">
                    <img src={scheck} alt="securitychck" />
                  </div>
                  <h2>Security Check Please Wait..</h2>
                  <p>
                    Please have your boarding pass and identification ready for
                    inspection.
                  </p>
                </div>
              </div>
            </Col> */}

            <Col md={6} lg={4}>
              <div className="main_outer_global_box slideDown">
                <div className="box_heading">Enter Your Flight Details</div>
                <BackBTN/>
                <div className="form global_bg_box text-center">
                  <div className="securtiy_checkbox text-center">
                    <img src={aeroplan} alt="securitychck" />
                  </div>
                  <h2>Enter Your Flight Number</h2>
                
                  <input
                    className="mt-2"
                    type="text"
                    placeholder="Flight number"
                  />
                  <div className="text-end">
                    <Link to="/inquiryandshop" className="button1 mt-3">
                      Submit
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Boardflight;
